import './site';
import jQuery from 'jquery';
window.jQuery = jQuery;
window.jQuery.fn.extend({
	charToggle: function () {
		return jQuery(this).text(jQuery(this).text() == '▶' ? '▼' : '▶');
	},
	focusing: function () {
		if (window.glb.isTouch) {
			return false;
		}
		return jQuery(this).focus(); 
	}
});
import App from '~/components/App.svelte';

const app = new App({
	target: document.body,
});

// window.app = app;

export default app;
