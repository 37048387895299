/* components/PwaPopup.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

import localforage from 'localforage';

function create_if_block(ctx) {
	let div2;
	let div0;
	let t1;
	let div1;
	let div2_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			div0.textContent = "Add to Your Homescreen";
			t1 = space();
			div1 = element("div");
			div1.innerHTML = `<span class="svelte-1mpwbof">↓</span>`;
			attr(div0, "class", "pwa-popup-msg svelte-1mpwbof");
			attr(div1, "class", "pwa-popup-arrow svelte-1mpwbof");
			attr(div2, "class", div2_class_value = "pwa-popup-wrapper " + /*platform*/ ctx[0] + " svelte-1mpwbof");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t1);
			append(div2, div1);

			if (!mounted) {
				dispose = listen(div2, "click", /*click_handler*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*platform*/ 1 && div2_class_value !== (div2_class_value = "pwa-popup-wrapper " + /*platform*/ ctx[0] + " svelte-1mpwbof")) {
				attr(div2, "class", div2_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div2);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*visible*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*visible*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const isStandalone = !!window.matchMedia && window.matchMedia('(display-mode: standalone)').matches;
	const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0; // works on IE10/11 and Surface
	const isAndorid = (/android/i).test(navigator.userAgent);
	const isIos = (/iphone|ipad/i).test(navigator.userAgent);
	let platform = '';
	let visible = false;

	if (!isStandalone && isTouch) {
		platform = isAndorid ? 'android' : isIos ? 'ios' : '';

		localforage.getItem('pwa_popup_count').then(value => {
			if (value === null) {
				value = 0;
			} else {
				value = Number(value);
			}

			if (value !== 0 && value % 5 === 0 || value === 1) {
				$$invalidate(1, visible = true);

				setTimeout(
					() => {
						$$invalidate(1, visible = false);
					},
					value === 1 ? 15000 : 7000
				);
			}

			localforage.setItem('pwa_popup_count', ++value);
		});
	}

	const click_handler = () => $$invalidate(1, visible = false);
	return [platform, visible, click_handler];
}

class PwaPopup extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PwaPopup;