/* components/App.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	construct_svelte_component,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from 'svelte';
import Modal from '~/components/Modal.svelte';
import FormFeedback from '~/components/FormFeedback.svelte';
import routes from '~/stores/routes.js';
import BlockPage from '~/components/BlockPage.svelte';
import Index from '~/pages/Index.svelte';
import Main from '~/pages/Main.svelte';
import Test from '~/pages/Test.svelte';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (37:2) {:else}
function create_else_block(ctx) {
	let index;
	let current;
	index = new Index({});

	return {
		c() {
			create_component(index.$$.fragment);
		},
		m(target, anchor) {
			mount_component(index, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(index.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(index.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(index, detaching);
		}
	};
}

// (33:2) {#if $routes.length > 0}
function create_if_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*$routes*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*pages, $routes*/ 3) {
				each_value = /*$routes*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (34:3) {#each $routes as route}
function create_each_block(ctx) {
	let switch_instance;
	let switch_instance_anchor;
	let current;
	var switch_value = /*pages*/ ctx[1][/*route*/ ctx[2].component];

	function switch_props(ctx) {
		return {
			props: { params: /*route*/ ctx[2].params }
		};
	}

	if (switch_value) {
		switch_instance = construct_svelte_component(switch_value, switch_props(ctx));
	}

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			if (switch_instance) mount_component(switch_instance, target, anchor);
			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const switch_instance_changes = {};
			if (dirty & /*$routes*/ 1) switch_instance_changes.params = /*route*/ ctx[2].params;

			if (dirty & /*$routes*/ 1 && switch_value !== (switch_value = /*pages*/ ctx[1][/*route*/ ctx[2].component])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = construct_svelte_component(switch_value, switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

function create_fragment(ctx) {
	let formfeedback;
	let t0;
	let modal;
	let t1;
	let blockpage;
	let t2;
	let div1;
	let div0;
	let current_block_type_index;
	let if_block;
	let current;
	formfeedback = new FormFeedback({});
	modal = new Modal({});
	blockpage = new BlockPage({});
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$routes*/ ctx[0].length > 0) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			create_component(formfeedback.$$.fragment);
			t0 = space();
			create_component(modal.$$.fragment);
			t1 = space();
			create_component(blockpage.$$.fragment);
			t2 = space();
			div1 = element("div");
			div0 = element("div");
			if_block.c();
			attr(div0, "class", "inner shadow p-3 p-sm-4 position-relative w-100 rounded my-0 my-sm-4 svelte-92sehb");
			attr(div1, "id", "app");
			attr(div1, "class", "d-flex align-items-center svelte-92sehb");
		},
		m(target, anchor) {
			mount_component(formfeedback, target, anchor);
			insert(target, t0, anchor);
			mount_component(modal, target, anchor);
			insert(target, t1, anchor);
			mount_component(blockpage, target, anchor);
			insert(target, t2, anchor);
			insert(target, div1, anchor);
			append(div1, div0);
			if_blocks[current_block_type_index].m(div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div0, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(formfeedback.$$.fragment, local);
			transition_in(modal.$$.fragment, local);
			transition_in(blockpage.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(formfeedback.$$.fragment, local);
			transition_out(modal.$$.fragment, local);
			transition_out(blockpage.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			destroy_component(formfeedback, detaching);
			if (detaching) detach(t0);
			destroy_component(modal, detaching);
			if (detaching) detach(t1);
			destroy_component(blockpage, detaching);
			if (detaching) detach(t2);
			if (detaching) detach(div1);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $routes;
	component_subscribe($$self, routes, $$value => $$invalidate(0, $routes = $$value));
	const pages = { s: Main, test: Test };

	onMount(() => {
		window.routes.init({
			rootOnStart: false,
			callback: segments => {
				console.log(`segments`, segments);
			}
		});
	});

	return [$routes, pages];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;