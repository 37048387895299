/* pages/Test.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	text
} from "svelte/internal";

function create_fragment(ctx) {
	let t0;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			t0 = text("Test\n\n");
			button = element("button");
			button.textContent = "back";
			attr(button, "class", "btn btn-primary");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[0]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self) {
	const click_handler = () => {
		window.routes.pop();
	};

	return [click_handler];
}

class Test extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Test;