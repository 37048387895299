/* pages/Transactions.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	outro_and_destroy_block,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import { onMount } from 'svelte';
import comma from '~/tools/comma';
import transactions from '~/stores/transactions';
import TransactionForm from './TransactionForm.svelte';
import MoimHelp from './MoimHelp.svelte';
import Dropdown from '~/components/Dropdown.svelte';
import Spinner from '~/components/Spinner.svelte';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (57:1) {#if $transactions.rows.length === 0}
function create_if_block_5(ctx) {
	let div;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");

			div.innerHTML = `<i class="bi bi-question-circle"></i>
			사용법 안내를 보시겠습니까?`;

			attr(div, "class", "py-4 mt-3 alert alert-info text-center cursor-pointer");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(div, "click", /*handleHelp*/ ctx[2]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (65:3) {#if transaction.status === 'enabled'}
function create_if_block_4(ctx) {
	let dropdown;
	let current;

	dropdown = new Dropdown({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(dropdown.$$.fragment);
		},
		m(target, anchor) {
			mount_component(dropdown, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const dropdown_changes = {};

			if (dirty & /*$$scope, $transactions*/ 2050) {
				dropdown_changes.$$scope = { dirty, ctx };
			}

			dropdown.$set(dropdown_changes);
		},
		i(local) {
			if (current) return;
			transition_in(dropdown.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(dropdown.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(dropdown, detaching);
		}
	};
}

// (66:3) <Dropdown>
function create_default_slot(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "취소거래 생성";
			attr(button, "class", "dropdown-item");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*handleCancel*/ ctx[4].bind(null, /*transaction*/ ctx[5].id))) /*handleCancel*/ ctx[4].bind(null, /*transaction*/ ctx[5].id).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (89:19) {#if user.amount != 0}
function create_if_block_3(ctx) {
	let t0;
	let t1_value = comma(/*user*/ ctx[8].amount) + "";
	let t1;
	let t2;

	return {
		c() {
			t0 = text("(");
			t1 = text(t1_value);
			t2 = text(")");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$transactions*/ 2 && t1_value !== (t1_value = comma(/*user*/ ctx[8].amount) + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

// (90:8) {#if user.isJoin !== undefined && !user.isJoin}
function create_if_block_2(ctx) {
	let t;

	return {
		c() {
			t = text("- 돈만 냄");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (87:6) {#each transaction.logs as user}
function create_each_block_1(ctx) {
	let span;
	let t0_value = /*user*/ ctx[8].name + "";
	let t0;
	let t1;
	let if_block0 = /*user*/ ctx[8].amount != 0 && create_if_block_3(ctx);
	let if_block1 = /*user*/ ctx[8].isJoin !== undefined && !/*user*/ ctx[8].isJoin && create_if_block_2(ctx);

	return {
		c() {
			span = element("span");
			t0 = text(t0_value);
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			attr(span, "class", "badge me-1 mb-1");
			toggle_class(span, "bg-secondary", /*user*/ ctx[8].amount == 0);
			toggle_class(span, "bg-info", /*user*/ ctx[8].amount != 0);
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t0);
			if (if_block0) if_block0.m(span, null);
			append(span, t1);
			if (if_block1) if_block1.m(span, null);
		},
		p(ctx, dirty) {
			if (dirty & /*$transactions*/ 2 && t0_value !== (t0_value = /*user*/ ctx[8].name + "")) set_data(t0, t0_value);

			if (/*user*/ ctx[8].amount != 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(span, t1);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*user*/ ctx[8].isJoin !== undefined && !/*user*/ ctx[8].isJoin) {
				if (if_block1) {
					
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(span, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*$transactions*/ 2) {
				toggle_class(span, "bg-secondary", /*user*/ ctx[8].amount == 0);
			}

			if (dirty & /*$transactions*/ 2) {
				toggle_class(span, "bg-info", /*user*/ ctx[8].amount != 0);
			}
		},
		d(detaching) {
			if (detaching) detach(span);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

// (63:1) {#each $transactions.rows as transaction (transaction.id)}
function create_each_block(key_1, ctx) {
	let div13;
	let t0;
	let div9;
	let div2;
	let div0;
	let t2;
	let div1;
	let t3_value = /*transaction*/ ctx[5].date + "";
	let t3;
	let t4;
	let div5;
	let div3;
	let t6;
	let div4;
	let t7_value = comma(/*transaction*/ ctx[5].amount) + "";
	let t7;
	let t8;
	let div8;
	let div6;
	let t10;
	let div7;
	let t11_value = /*transaction*/ ctx[5].memo + "";
	let t11;
	let t12;
	let div12;
	let div11;
	let div10;
	let div13_class_value;
	let current;
	let if_block = /*transaction*/ ctx[5].status === 'enabled' && create_if_block_4(ctx);
	let each_value_1 = /*transaction*/ ctx[5].logs;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		key: key_1,
		first: null,
		c() {
			div13 = element("div");
			if (if_block) if_block.c();
			t0 = space();
			div9 = element("div");
			div2 = element("div");
			div0 = element("div");
			div0.textContent = "날짜";
			t2 = space();
			div1 = element("div");
			t3 = text(t3_value);
			t4 = space();
			div5 = element("div");
			div3 = element("div");
			div3.textContent = "총 결제액";
			t6 = space();
			div4 = element("div");
			t7 = text(t7_value);
			t8 = space();
			div8 = element("div");
			div6 = element("div");
			div6.textContent = "사용처 및 메모";
			t10 = space();
			div7 = element("div");
			t11 = text(t11_value);
			t12 = space();
			div12 = element("div");
			div11 = element("div");
			div10 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "label svelte-royrae");
			attr(div2, "class", "col-12 col-sm-4 mb-1");
			attr(div3, "class", "label svelte-royrae");
			attr(div5, "class", "col-12 col-sm-4 mb-1");
			attr(div6, "class", "label svelte-royrae");
			attr(div8, "class", "col-12 col-sm-4 mb-1");
			attr(div9, "class", "row mb-3 svelte-royrae");
			attr(div11, "class", "col");
			attr(div12, "class", "row mb-3 svelte-royrae");
			attr(div13, "class", div13_class_value = "item border p-3 mb-2 position-relative rounded pb-1 status-" + /*transaction*/ ctx[5].status + " svelte-royrae");
			this.first = div13;
		},
		m(target, anchor) {
			insert(target, div13, anchor);
			if (if_block) if_block.m(div13, null);
			append(div13, t0);
			append(div13, div9);
			append(div9, div2);
			append(div2, div0);
			append(div2, t2);
			append(div2, div1);
			append(div1, t3);
			append(div9, t4);
			append(div9, div5);
			append(div5, div3);
			append(div5, t6);
			append(div5, div4);
			append(div4, t7);
			append(div9, t8);
			append(div9, div8);
			append(div8, div6);
			append(div8, t10);
			append(div8, div7);
			append(div7, t11);
			append(div13, t12);
			append(div13, div12);
			append(div12, div11);
			append(div11, div10);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div10, null);
				}
			}

			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (/*transaction*/ ctx[5].status === 'enabled') {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$transactions*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div13, t0);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if ((!current || dirty & /*$transactions*/ 2) && t3_value !== (t3_value = /*transaction*/ ctx[5].date + "")) set_data(t3, t3_value);
			if ((!current || dirty & /*$transactions*/ 2) && t7_value !== (t7_value = comma(/*transaction*/ ctx[5].amount) + "")) set_data(t7, t7_value);
			if ((!current || dirty & /*$transactions*/ 2) && t11_value !== (t11_value = /*transaction*/ ctx[5].memo + "")) set_data(t11, t11_value);

			if (dirty & /*$transactions, undefined, comma*/ 2) {
				each_value_1 = /*transaction*/ ctx[5].logs;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div10, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (!current || dirty & /*$transactions*/ 2 && div13_class_value !== (div13_class_value = "item border p-3 mb-2 position-relative rounded pb-1 status-" + /*transaction*/ ctx[5].status + " svelte-royrae")) {
				attr(div13, "class", div13_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div13);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
		}
	};
}

// (101:1) {#if $transactions.fetchStatus === 'pending'}
function create_if_block_1(ctx) {
	let spinner;
	let current;
	spinner = new Spinner({ props: { overlay: 0.5 } });

	return {
		c() {
			create_component(spinner.$$.fragment);
		},
		m(target, anchor) {
			mount_component(spinner, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(spinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(spinner, detaching);
		}
	};
}

// (106:0) {#if $transactions.isMore}
function create_if_block(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "더보기";
			attr(button, "class", "btn btn-secondary-outline");
			attr(div, "class", "d-grid");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(transactions.fetch.bind(null, /*slug*/ ctx[0]))) transactions.fetch.bind(null, /*slug*/ ctx[0]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let button;
	let t1;
	let t2;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t3;
	let t4;
	let if_block2_anchor;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*$transactions*/ ctx[1].rows.length === 0 && create_if_block_5(ctx);
	let each_value = /*$transactions*/ ctx[1].rows;
	const get_key = ctx => /*transaction*/ ctx[5].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	let if_block1 = /*$transactions*/ ctx[1].fetchStatus === 'pending' && create_if_block_1(ctx);
	let if_block2 = /*$transactions*/ ctx[1].isMore && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			button = element("button");

			button.innerHTML = `<i class="bi bi-plus-lg"></i>
			거래 추가`;

			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t3 = space();
			if (if_block1) if_block1.c();
			t4 = space();
			if (if_block2) if_block2.c();
			if_block2_anchor = empty();
			attr(button, "class", "btn btn-primary btn-lg");
			attr(div0, "class", "d-grid mb-4");
			attr(div1, "id", "transactions-list");
			attr(div1, "class", "position-relative svelte-royrae");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button);
			append(div1, t1);
			if (if_block0) if_block0.m(div1, null);
			append(div1, t2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			append(div1, t3);
			if (if_block1) if_block1.m(div1, null);
			insert(target, t4, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, if_block2_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*handleCreate*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*$transactions*/ ctx[1].rows.length === 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_5(ctx);
					if_block0.c();
					if_block0.m(div1, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*$transactions, undefined, comma, handleCancel*/ 18) {
				each_value = /*$transactions*/ ctx[1].rows;
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div1, outro_and_destroy_block, create_each_block, t3, get_each_context);
				check_outros();
			}

			if (/*$transactions*/ ctx[1].fetchStatus === 'pending') {
				if (if_block1) {
					if (dirty & /*$transactions*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*$transactions*/ ctx[1].isMore) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(if_block2_anchor.parentNode, if_block2_anchor);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block1);
			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (if_block1) if_block1.d();
			if (detaching) detach(t4);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(if_block2_anchor);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $transactions;
	component_subscribe($$self, transactions, $$value => $$invalidate(1, $transactions = $$value));
	let { slug = '' } = $$props;

	const handleHelp = () => {
		window.modal.open({
			title: '모임 사용법',
			size: 'xs',
			content: MoimHelp,
			cancelLabel: '',
			props: { slug }
		});
	};

	const handleCreate = () => {
		window.modal.open({
			id: 'create',
			size: 'xs',
			title: '거래 추가',
			content: TransactionForm,
			autoFocus: false,
			props: { slug }
		});
	};

	const handleCancel = transactionId => {
		window.modal.confirm('이 거래를 무효화하기 위하여 취소거래가 생성됩니다. 계속하시겠습니까?', () => {
			transactions.cancel(slug, transactionId);
		});
	};

	onMount(() => {
		transactions.fetch(slug);
	});

	$$self.$$set = $$props => {
		if ('slug' in $$props) $$invalidate(0, slug = $$props.slug);
	};

	return [slug, $transactions, handleHelp, handleCreate, handleCancel];
}

class Transactions extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { slug: 0 });
	}
}

export default Transactions;