/* components/Dropdown.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

function create_fragment(ctx) {
	let div;
	let button;
	let t0;
	let button_class_value;
	let t1;
	let ul;
	let div_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			div = element("div");
			button = element("button");
			t0 = text(/*label*/ ctx[0]);
			t1 = space();
			ul = element("ul");
			if (default_slot) default_slot.c();
			attr(button, "tabindex", "-1");
			attr(button, "class", button_class_value = "btn dropdown-toggle ms-2 " + /*btnClass*/ ctx[1] + " svelte-1izjhz7");
			attr(button, "type", "button");
			attr(button, "data-bs-toggle", "dropdown");
			attr(button, "aria-expanded", "false");
			attr(ul, "class", "dropdown-menu dropdown-menu-end shadow svelte-1izjhz7");

			attr(div, "class", div_class_value = "skip dropdown-component btn-group dropdown " + (/*clazz*/ ctx[2].includes('relative')
			? ''
			: 'position-absolute') + " top-0 end-0 " + /*clazz*/ ctx[2] + " z-1" + " svelte-1izjhz7");

			set_style(div, "transform", "rotate(" + /*rotate*/ ctx[3] * -1 + "deg)");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);
			append(button, t0);
			append(div, t1);
			append(div, ul);

			if (default_slot) {
				default_slot.m(ul, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*label*/ 1) set_data(t0, /*label*/ ctx[0]);

			if (!current || dirty & /*btnClass*/ 2 && button_class_value !== (button_class_value = "btn dropdown-toggle ms-2 " + /*btnClass*/ ctx[1] + " svelte-1izjhz7")) {
				attr(button, "class", button_class_value);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[4], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*clazz*/ 4 && div_class_value !== (div_class_value = "skip dropdown-component btn-group dropdown " + (/*clazz*/ ctx[2].includes('relative')
			? ''
			: 'position-absolute') + " top-0 end-0 " + /*clazz*/ ctx[2] + " z-1" + " svelte-1izjhz7")) {
				attr(div, "class", div_class_value);
			}

			if (!current || dirty & /*rotate*/ 8) {
				set_style(div, "transform", "rotate(" + /*rotate*/ ctx[3] * -1 + "deg)");
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { label = '' } = $$props;
	let { btnClass = '' } = $$props;
	let { class: clazz = '' } = $$props;
	let { rotate = 0 } = $$props;

	$$self.$$set = $$props => {
		if ('label' in $$props) $$invalidate(0, label = $$props.label);
		if ('btnClass' in $$props) $$invalidate(1, btnClass = $$props.btnClass);
		if ('class' in $$props) $$invalidate(2, clazz = $$props.class);
		if ('rotate' in $$props) $$invalidate(3, rotate = $$props.rotate);
		if ('$$scope' in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	return [label, btnClass, clazz, rotate, $$scope, slots];
}

class Dropdown extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			label: 0,
			btnClass: 1,
			class: 2,
			rotate: 3
		});
	}
}

export default Dropdown;