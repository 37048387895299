import { writable, get } from 'svelte/store';
import ftch from '~/tools/ftch';

const moims = (() => {
	const _this = writable({
		fetchStatus: 'pending',
		id: 0,
		status: '',
		slug: '',
		name: '',
		memo: '',
		users: []
	});
	const { subscribe, set, update } = _this;

	const setIn = (key, value) => update(_ => {
		_[key] = value;
		return _;
	});

	return {
		subscribe,
		set,
		setIn,

		fetch: async (slug = '') => {
			setIn('fetchStatus', 'pending');
			const { status, message, result} = await ftch('get', '/api/moims', {
				slug
			});
			if (status === 'fail') {
				alert(message);
				if (sessionStorage.__failed === undefined) {
					sessionStorage.__failed = 0;
				}
				sessionStorage.__failed = sessionStorage.__failed + 1;
				if (sessionStorage.__failed > 3) {
					sessionStorage.removeItem('__failed');
					return location.replace('/');
				}
				return location.reload();
			}

			const _ = get(_this);
			_.fetchStatus = '';
			Object.entries(result).forEach(([k, v]) => {
				_[k] = v;
			});
			window.glb.setTitle(_.name || '무명의 모임');
			set(_);
		},

		create: async (name = '', callback) => {
			window.blockPage('add');
			const { status, message, slug } = await ftch('post', '/api/moims', {name});
			window.blockPage('remove');
			if (status === 'done') {
				typeof callback === 'function' && callback(slug);
			} else {
				window.ffb(message, 'error');
			}
		},

		update: async (data, callback, cancelCallback) => {
			const { status, message } = await ftch('post', '/api/moims', data);
			if (status === 'done') {
				moims.fetch(data.slug);
				window.ffb(message);
				typeof callback === 'function' && callback();
			} else {
				window.ffb(message, 'error');
				typeof cancelCallback === 'function' && cancelCallback();
			}
		},

		delete: async (slug = '') => {
			window.blockPage('add');
			const { status, message } = await ftch('delete', `/api/moims/${slug}/delete`)
			window.blockPage('remove');
			if (status === 'done') {
				moims.fetch(slug);
				window.modal.alert(message);
			} else {
				window.ffb(message, 'error');
			}
		},

		restore: async (slug = '') => {
			window.blockPage('add');
			const { status, message } = await ftch('delete', `/api/moims/${slug}/restore`)
			window.blockPage('remove');
			if (status === 'done') {
				moims.fetch(slug);
				window.modal.alert(message);
			} else {
				window.ffb(message, 'error');
			}
		},

		resetTransaction: async(slug = '', callback, cancelCallback) => {
			const { status, message } = await ftch('delete', `/api/moims/${slug}/reset`)
			if (status === 'done') {
				window.modal.alert(message, callback);
			} else {
				window.ffb(message, 'error');
				typeof cancelCallback === 'function' && cancelCallback();
			}
		}
	};
})();

export default moims;