/* pages/Moim.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_block,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import { onMount } from 'svelte';
import comma from '~/tools/comma';
import moims from '~/stores/moims';
import Dropdown from '~/components/Dropdown.svelte';
import Spinner from '~/components/Spinner.svelte';
import MoimForm from './MoimForm.svelte';
import MoimUrl from './MoimUrl.svelte';
import MoimHelp from './MoimHelp.svelte';
import MoimReset from './MoimReset.svelte';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (79:0) {#if $moims.status === 'disabled'}
function create_if_block_3(ctx) {
	let div2;
	let div0;
	let t1;
	let button;
	let t3;
	let div1;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			div0.textContent = "일주일 이내에 삭제취소가 이루어지지 않으면 삭제됩니다(예정)";
			t1 = space();
			button = element("button");
			button.textContent = "삭제 취소";
			t3 = space();
			div1 = element("div");

			div1.innerHTML = `<a class="btn btn-link text-decoration-none" href="/" style="font-size:0.9em;color:gray;"><i class="bi bi-house"></i>
				초기화면</a>`;

			attr(div0, "class", "fs-5 mb-3");
			attr(button, "class", "btn btn-link");
			attr(div1, "class", "py-3 mt-4 text-center");
			attr(div2, "class", "position-fixed start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center flex-column z-1");
			set_style(div2, "background", "rgba(255,255,255,0.9)");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t1);
			append(div2, button);
			append(div2, t3);
			append(div2, div1);

			if (!mounted) {
				dispose = listen(button, "click", /*handleRestore*/ ctx[6]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div2);
			mounted = false;
			dispose();
		}
	};
}

// (103:2) {#if $moims.resettable}
function create_if_block_2(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.innerHTML = `<i class="bi bi-x-circle"></i> 데이터 리셋`;
			attr(button, "class", "dropdown-item text-danger");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*handleReset*/ ctx[5]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (97:1) <Dropdown label='설정'>
function create_default_slot(ctx) {
	let button0;
	let t1;
	let button1;
	let t3;
	let li0;
	let t4;
	let button2;
	let t6;
	let li1;
	let t7;
	let t8;
	let button3;
	let mounted;
	let dispose;
	let if_block = /*$moims*/ ctx[0].resettable && create_if_block_2(ctx);

	return {
		c() {
			button0 = element("button");
			button0.textContent = "모임 사용법";
			t1 = space();
			button1 = element("button");
			button1.textContent = "모임 주소안내";
			t3 = space();
			li0 = element("li");
			li0.innerHTML = `<hr class="dropdown-divider"/>`;
			t4 = space();
			button2 = element("button");
			button2.innerHTML = `<i class="bi bi-tools"></i> 모임(구성원) 수정`;
			t6 = space();
			li1 = element("li");
			li1.innerHTML = `<hr class="dropdown-divider"/>`;
			t7 = space();
			if (if_block) if_block.c();
			t8 = space();
			button3 = element("button");
			button3.innerHTML = `<i class="bi bi-trash3"></i> 모임 삭제`;
			attr(button0, "class", "dropdown-item");
			attr(button1, "class", "dropdown-item");
			attr(button2, "class", "dropdown-item");
			attr(button3, "class", "dropdown-item text-danger");
		},
		m(target, anchor) {
			insert(target, button0, anchor);
			insert(target, t1, anchor);
			insert(target, button1, anchor);
			insert(target, t3, anchor);
			insert(target, li0, anchor);
			insert(target, t4, anchor);
			insert(target, button2, anchor);
			insert(target, t6, anchor);
			insert(target, li1, anchor);
			insert(target, t7, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, t8, anchor);
			insert(target, button3, anchor);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*handleHelp*/ ctx[1]),
					listen(button1, "click", /*handleUrl*/ ctx[2]),
					listen(button2, "click", /*handleUpdate*/ ctx[3]),
					listen(button3, "click", /*handleDelete*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (/*$moims*/ ctx[0].resettable) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(t8.parentNode, t8);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(button0);
			if (detaching) detach(t1);
			if (detaching) detach(button1);
			if (detaching) detach(t3);
			if (detaching) detach(li0);
			if (detaching) detach(t4);
			if (detaching) detach(button2);
			if (detaching) detach(t6);
			if (detaching) detach(li1);
			if (detaching) detach(t7);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t8);
			if (detaching) detach(button3);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (112:2) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text(" ");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (110:2) {#if $moims.fetchStatus !== 'pending'}
function create_if_block_1(ctx) {
	let t_value = (/*$moims*/ ctx[0].name || '무명의 모임') + "";
	let t;

	return {
		c() {
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$moims*/ 1 && t_value !== (t_value = (/*$moims*/ ctx[0].name || '무명의 모임') + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (117:1) {#each $moims.users as user (user.id)}
function create_each_block(key_1, ctx) {
	let div2;
	let div0;
	let t0_value = /*user*/ ctx[8].name + "";
	let t0;
	let t1;
	let div1;
	let t2_value = comma(/*user*/ ctx[8].remains * -1) + "";
	let t2;

	return {
		key: key_1,
		first: null,
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			t2 = text(t2_value);
			attr(div0, "class", "opacity-75");
			attr(div1, "class", "fw-bold opacity-75");
			attr(div2, "class", "item py-2 border-bottom d-flex justify-content-between fs-5 svelte-1ccbza1");
			this.first = div2;
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, t0);
			append(div2, t1);
			append(div2, div1);
			append(div1, t2);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$moims*/ 1 && t0_value !== (t0_value = /*user*/ ctx[8].name + "")) set_data(t0, t0_value);
			if (dirty & /*$moims*/ 1 && t2_value !== (t2_value = comma(/*user*/ ctx[8].remains * -1) + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(div2);
		}
	};
}

// (124:1) {#if $moims.fetchStatus === 'pending'}
function create_if_block(ctx) {
	let spinner;
	let current;
	spinner = new Spinner({ props: { overlay: 0.5 } });

	return {
		c() {
			create_component(spinner.$$.fragment);
		},
		m(target, anchor) {
			mount_component(spinner, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(spinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(spinner, detaching);
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let div;
	let dropdown;
	let t1;
	let h1;
	let t2;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t3;
	let current;
	let if_block0 = /*$moims*/ ctx[0].status === 'disabled' && create_if_block_3(ctx);

	dropdown = new Dropdown({
			props: {
				label: "설정",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	function select_block_type(ctx, dirty) {
		if (/*$moims*/ ctx[0].fetchStatus !== 'pending') return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block1 = current_block_type(ctx);
	let each_value = /*$moims*/ ctx[0].users;
	const get_key = ctx => /*user*/ ctx[8].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	let if_block2 = /*$moims*/ ctx[0].fetchStatus === 'pending' && create_if_block(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			div = element("div");
			create_component(dropdown.$$.fragment);
			t1 = space();
			h1 = element("h1");
			if_block1.c();
			t2 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t3 = space();
			if (if_block2) if_block2.c();
			attr(h1, "class", "mt-1 mb-3");
			attr(div, "id", "moim");
			attr(div, "class", "position-relative svelte-1ccbza1");
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			mount_component(dropdown, div, null);
			append(div, t1);
			append(div, h1);
			if_block1.m(h1, null);
			append(div, t2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			append(div, t3);
			if (if_block2) if_block2.m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$moims*/ ctx[0].status === 'disabled') {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			const dropdown_changes = {};

			if (dirty & /*$$scope, $moims*/ 2049) {
				dropdown_changes.$$scope = { dirty, ctx };
			}

			dropdown.$set(dropdown_changes);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if_block1.d(1);
				if_block1 = current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(h1, null);
				}
			}

			if (dirty & /*comma, $moims*/ 1) {
				each_value = /*$moims*/ ctx[0].users;
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, destroy_block, create_each_block, t3, get_each_context);
			}

			if (/*$moims*/ ctx[0].fetchStatus === 'pending') {
				if (if_block2) {
					if (dirty & /*$moims*/ 1) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(dropdown.$$.fragment, local);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(dropdown.$$.fragment, local);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div);
			destroy_component(dropdown);
			if_block1.d();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (if_block2) if_block2.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $moims;
	component_subscribe($$self, moims, $$value => $$invalidate(0, $moims = $$value));
	let { slug = '' } = $$props;

	const handleHelp = () => {
		window.modal.open({
			title: '모임 사용법',
			size: 'xs',
			content: MoimHelp,
			cancelLabel: '',
			props: { slug }
		});
	};

	const handleUrl = () => {
		window.modal.open({
			title: '모임 주소',
			size: 'xs',
			content: MoimUrl,
			cancelLabel: '',
			props: { slug }
		});
	};

	const handleUpdate = () => {
		window.modal.open({
			id: 'update moim',
			size: 'xs',
			title: '모임 수정',
			content: MoimForm,
			closeOnBack: false,
			props: { slug }
		});
	};

	const handleDelete = () => {
		window.modal.confirm('정말로 삭제하시겠습니까?', () => {
			moims.delete(slug);
		});
	};

	const handleReset = () => {
		window.modal.open({
			id: 'reset',
			size: 'xs',
			content: MoimReset,
			props: { slug }
		});
	};

	const handleRestore = () => {
		window.modal.confirm('삭제를 취소하시겠습니까?', () => {
			moims.restore(slug);
		});
	};

	onMount(() => {
		moims.fetch(slug);
	});

	$$self.$$set = $$props => {
		if ('slug' in $$props) $$invalidate(7, slug = $$props.slug);
	};

	return [
		$moims,
		handleHelp,
		handleUrl,
		handleUpdate,
		handleDelete,
		handleReset,
		handleRestore,
		slug
	];
}

class Moim extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { slug: 7 });
	}
}

export default Moim;