/* components/FormFeedback.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	create_bidirectional_transition,
	detach,
	element,
	group_outros,
	init,
	insert,
	outro_and_destroy_block,
	safe_not_equal,
	space,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import { onMount } from 'svelte';
import { fade } from 'svelte/transition';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (83:1) {#each rows as row (row.id)}
function create_each_block(key_1, ctx) {
	let br;
	let t0;
	let div;
	let span;
	let raw_value = /*row*/ ctx[4].msg + "";
	let t1;
	let div_class_value;
	let div_data_id_value;
	let div_transition;
	let current;

	return {
		key: key_1,
		first: null,
		c() {
			br = element("br");
			t0 = space();
			div = element("div");
			span = element("span");
			t1 = space();
			attr(span, "class", "svelte-1fpgotb");
			attr(div, "class", div_class_value = "ffb-item rounded shadow mb-1 type-" + /*row*/ ctx[4].type + " svelte-1fpgotb");
			attr(div, "data-id", div_data_id_value = /*row*/ ctx[4].id);
			this.first = br;
		},
		m(target, anchor) {
			insert(target, br, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			append(div, span);
			span.innerHTML = raw_value;
			append(div, t1);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if ((!current || dirty & /*rows*/ 1) && raw_value !== (raw_value = /*row*/ ctx[4].msg + "")) span.innerHTML = raw_value;;

			if (!current || dirty & /*rows*/ 1 && div_class_value !== (div_class_value = "ffb-item rounded shadow mb-1 type-" + /*row*/ ctx[4].type + " svelte-1fpgotb")) {
				attr(div, "class", div_class_value);
			}

			if (!current || dirty & /*rows*/ 1 && div_data_id_value !== (div_data_id_value = /*row*/ ctx[4].id)) {
				attr(div, "data-id", div_data_id_value);
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!current) return;
				if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, true);
				div_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!div_transition) div_transition = create_bidirectional_transition(div, fade, {}, false);
			div_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(br);
			if (detaching) detach(t0);
			if (detaching) detach(div);
			if (detaching && div_transition) div_transition.end();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let current;
	let each_value = /*rows*/ ctx[0];
	const get_key = ctx => /*row*/ ctx[4].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "id", "ffb-wrapper");
			attr(div, "class", "position-fixed pe-none svelte-1fpgotb");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*rows*/ 1) {
				each_value = /*rows*/ ctx[0];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, outro_and_destroy_block, create_each_block, null, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

const delayPerChar = 200;

function instance($$self, $$props, $$invalidate) {
	let rows = [];
	let id = 0;

	const open = (obj = {}, type = '', skipIfTypeExist = false) => {
		if (skipIfTypeExist) {
			// 완전히 동일한 타입이 이미 있다면 생략
			if (rows.findIndex(row => row.type === type) > -1) {
				return false;
			}
		}

		if (typeof obj !== 'object') {
			obj = { msg: obj };

			switch (obj.msg) {
				case 'wrote':
				case 'created':
				case 'insertd':
					{
						obj.msg = '추가되었습니다';
						break;
					}
				case 'updated':
					{
						obj.msg = '수정되었습니다';
						break;
					}
				case 'deleted':
					{
						obj.msg = '삭제되었습니다';
						break;
					}
			}

			if (obj.msg === '') {
				if (type === 'error') {
					obj.msg = '입력값에 에러가 있습니다';
				} else {
					obj.msg = '완료되었습니다';
				}
			}
		}

		obj.id = obj.id || `ffb-${id++}`;
		obj.type = obj.type || type;
		obj.callback = obj.callback;
		const existIndex = rows.findIndex(row => row.msg === obj.msg);

		if (existIndex > -1) {
			return false; // 완전히 동일한 메시지가 이미 있으면 생략
		}

		$$invalidate(0, rows = [...rows, obj]);
		let delayMsg = obj.msg.length * delayPerChar;

		if (delayMsg < 1500) {
			delayMsg = 1500;
		}

		setTimeout(
			() => {
				close(obj.id);
			},
			delayMsg
		);
	};

	const close = (id = '') => {
		if (id === '') {
			rows.pop();
		} else {
			const index = rows.findIndex(r => r.id === id);
			rows.splice(index, 1);
		}

		$$invalidate(0, rows);
	};

	onMount(() => {
		window.ffb = open;
	});

	return [rows];
}

class FormFeedback extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default FormFeedback;