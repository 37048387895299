/* components/Html.svelte generated by Svelte v3.58.0 */
import {
	HtmlTag,
	SvelteComponent,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from 'svelte';
import Spinner from '~/components/Spinner.svelte';

function create_else_block(ctx) {
	let html_tag;
	let html_anchor;

	return {
		c() {
			html_tag = new HtmlTag(false);
			html_anchor = empty();
			html_tag.a = html_anchor;
		},
		m(target, anchor) {
			html_tag.m(/*htmlContent*/ ctx[0], target, anchor);
			insert(target, html_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*htmlContent*/ 1) html_tag.p(/*htmlContent*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(html_anchor);
			if (detaching) html_tag.d();
		}
	};
}

// (27:1) {#if htmlContent === undefined}
function create_if_block(ctx) {
	let div;
	let spinner;
	let current;
	spinner = new Spinner({ props: { zoom: "50" } });

	return {
		c() {
			div = element("div");
			create_component(spinner.$$.fragment);
			attr(div, "class", "p-4");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(spinner, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(spinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(spinner);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*htmlContent*/ ctx[0] === undefined) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
			attr(div, "class", "position-relative");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_blocks[current_block_type_index].m(div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { url } = $$props;
	let htmlContent;

	onMount(() => {
		jQuery.ajaxWithHeader({ url, dataType: 'html' }).done(function (html) {
			var doc = new DOMParser().parseFromString(html, 'text/html');

			if (doc.querySelector('#pjax-body') !== null) {
				$$invalidate(0, htmlContent = doc.querySelector('#pjax-body').innerHTML);
			} else {
				$$invalidate(0, htmlContent = doc.querySelector('body').innerHTML);
			}
		});
	});

	$$self.$$set = $$props => {
		if ('url' in $$props) $$invalidate(1, url = $$props.url);
	};

	return [htmlContent, url];
}

class Html extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { url: 1 });
	}
}

export default Html;