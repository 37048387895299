/* pages/MoimUrl.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import copyToClipboard from '~/tools/copyToClipboard';

function create_if_block(ctx) {
	let p;
	let t;

	return {
		c() {
			p = element("p");
			t = text(/*welcomeMessage*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*welcomeMessage*/ 1) set_data(t, /*welcomeMessage*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let p;
	let t2;
	let div;
	let input;
	let t3;
	let button;
	let mounted;
	let dispose;
	let if_block = /*welcomeMessage*/ ctx[0] !== '' && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			t0 = space();
			p = element("p");
			p.textContent = "별도의 인증이 없이 아래 주소를 아는 사람들은 모두 접근과 수정이 가능합니다.\n\t따라서 꼭 필요한 인원들에게만 공유하시거나 아니면 본인만 관리하세요.\n\t주소를 잊어버리지 않도록 개인의 카카오톡이나 메모장에 백업해두는 것을 권장드립니다.";
			t2 = space();
			div = element("div");
			input = element("input");
			t3 = space();
			button = element("button");
			button.textContent = "Copy";
			attr(input, "type", "text");
			attr(input, "class", "form-control");
			input.value = /*url*/ ctx[1];
			input.readOnly = true;
			attr(button, "class", "btn btn-outline-secondary");
			attr(button, "type", "button");
			attr(div, "class", "input-group mb-3");
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t0, anchor);
			insert(target, p, anchor);
			insert(target, t2, anchor);
			insert(target, div, anchor);
			append(div, input);
			append(div, t3);
			append(div, button);

			if (!mounted) {
				dispose = [
					listen(input, "click", /*click_handler*/ ctx[3]),
					listen(button, "click", /*click_handler_1*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*welcomeMessage*/ ctx[0] !== '') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(t0.parentNode, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t0);
			if (detaching) detach(p);
			if (detaching) detach(t2);
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { slug = '' } = $$props;
	let { welcomeMessage = '' } = $$props;
	let url = `https://moim.whooing.com/s/${slug}`;

	const click_handler = e => {
		jQuery(e.target).select().focus();
	};

	const click_handler_1 = () => {
		copyToClipboard(url);
		window.ffb('주소가 클립보드에 복사되었습니다');
	};

	$$self.$$set = $$props => {
		if ('slug' in $$props) $$invalidate(2, slug = $$props.slug);
		if ('welcomeMessage' in $$props) $$invalidate(0, welcomeMessage = $$props.welcomeMessage);
	};

	return [welcomeMessage, url, slug, click_handler, click_handler_1];
}

class MoimUrl extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { slug: 2, welcomeMessage: 0 });
	}
}

export default MoimUrl;