export default function isEmpty (val) {
	if (val === undefined || val === null) {
		return true;
	}
	if (Array.isArray(val) && val.length === 0) {
		return true;
	}
	if (typeof val === 'object' && Object.keys(val).length === 0) {
		return true;
	}
	if (!val) { // if falsy
		return true;
	}

	if (val === '0') { // PHP empty와 동일시
		return true;
	}

	return false;
}