/* components/Spinner.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	toggle_class
} from "svelte/internal";

function create_if_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "position-absolute start-0 end-0 top-0 bottom-0");
			set_style(div, "background", "rgba(255,255,255, " + /*overlay*/ ctx[4] + ")");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*overlay*/ 16) {
				set_style(div, "background", "rgba(255,255,255, " + /*overlay*/ ctx[4] + ")");
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (25:0) {#if msg !== ''}
function create_if_block(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*msg*/ ctx[1]);
			attr(div, "class", "msg position-absolute start-0 w-100 text-center fs-5 svelte-5b5e");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*msg*/ 2) set_data(t, /*msg*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let span;
	let div;
	let span_class_value;
	let span_style_value;
	let t1;
	let if_block1_anchor;
	let if_block0 = /*overlay*/ ctx[4] > 0 && create_if_block_1(ctx);
	let if_block1 = /*msg*/ ctx[1] !== '' && create_if_block(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();
			span = element("span");
			div = element("div");
			t1 = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
			attr(div, "class", "spinner-border");
			attr(div, "role", "status");
			attr(span, "class", span_class_value = "spinner zoom-" + /*zoom*/ ctx[2] + " " + /*clazz*/ ctx[5] + " svelte-5b5e");

			attr(span, "style", span_style_value = `${/*style*/ ctx[0]};${/*type*/ ctx[3] === 'inline'
			? 'position:relative;top:4px;'
			: ''};`);

			toggle_class(span, "absolute-center", /*type*/ ctx[3] === 'absolute');
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);
			insert(target, span, anchor);
			append(span, div);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*overlay*/ ctx[4] > 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*zoom, clazz*/ 36 && span_class_value !== (span_class_value = "spinner zoom-" + /*zoom*/ ctx[2] + " " + /*clazz*/ ctx[5] + " svelte-5b5e")) {
				attr(span, "class", span_class_value);
			}

			if (dirty & /*style, type*/ 9 && span_style_value !== (span_style_value = `${/*style*/ ctx[0]};${/*type*/ ctx[3] === 'inline'
			? 'position:relative;top:4px;'
			: ''};`)) {
				attr(span, "style", span_style_value);
			}

			if (dirty & /*zoom, clazz, type*/ 44) {
				toggle_class(span, "absolute-center", /*type*/ ctx[3] === 'absolute');
			}

			if (/*msg*/ ctx[1] !== '') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t0);
			if (detaching) detach(span);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { style = '' } = $$props;
	let { msg = '' } = $$props;
	let { zoom = '75' } = $$props;
	let { type = 'absolute' } = $$props;
	let { overlay = 0 } = $$props;
	let { class: clazz = '' } = $$props;

	$$self.$$set = $$props => {
		if ('style' in $$props) $$invalidate(0, style = $$props.style);
		if ('msg' in $$props) $$invalidate(1, msg = $$props.msg);
		if ('zoom' in $$props) $$invalidate(2, zoom = $$props.zoom);
		if ('type' in $$props) $$invalidate(3, type = $$props.type);
		if ('overlay' in $$props) $$invalidate(4, overlay = $$props.overlay);
		if ('class' in $$props) $$invalidate(5, clazz = $$props.class);
	};

	return [style, msg, zoom, type, overlay, clazz];
}

class Spinner extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			style: 0,
			msg: 1,
			zoom: 2,
			type: 3,
			overlay: 4,
			class: 5
		});
	}
}

export default Spinner;