/* components/ModalForm.svelte generated by Svelte v3.58.0 */
import {
	HtmlTag,
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	destroy_block,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	prevent_default,
	safe_not_equal,
	set_input_value,
	space,
	text,
	to_number,
	update_keyed_each
} from "svelte/internal";

import { onMount } from 'svelte';
import ucfirst from '~/tools/ucfirst';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[12] = list;
	child_ctx[13] = i;
	return child_ctx;
}

// (51:4) {#if row.desc !== '' && row.key.length + row.desc.length <= 50}
function create_if_block_5(ctx) {
	let span;
	let t0;
	let html_tag;
	let raw_value = /*row*/ ctx[11].desc.replace('\n', '<br/>') + "";
	let t1;

	return {
		c() {
			span = element("span");
			t0 = text("(");
			html_tag = new HtmlTag(false);
			t1 = text(")");
			html_tag.a = t1;
			attr(span, "class", "fs-xs opacity-50");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t0);
			html_tag.m(raw_value, span);
			append(span, t1);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (92:33) 
function create_if_block_4(ctx) {
	let input;
	let input_id_value;
	let input_required_value;
	let input_placeholder_value;
	let mounted;
	let dispose;

	function input_input_handler_2() {
		/*input_input_handler_2*/ ctx[9].call(input, /*row*/ ctx[11]);
	}

	return {
		c() {
			input = element("input");
			attr(input, "type", "date");
			attr(input, "id", input_id_value = "label-" + /*row*/ ctx[11].key);
			attr(input, "class", "form-control");
			input.required = input_required_value = /*row*/ ctx[11].required;
			attr(input, "placeholder", input_placeholder_value = /*row*/ ctx[11].placeholder);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*store*/ ctx[1][/*row*/ ctx[11].key]);

			if (!mounted) {
				dispose = listen(input, "input", input_input_handler_2);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*store, rows*/ 6) {
				set_input_value(input, /*store*/ ctx[1][/*row*/ ctx[11].key]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (83:35) 
function create_if_block_3(ctx) {
	let input;
	let input_id_value;
	let input_required_value;
	let input_placeholder_value;
	let mounted;
	let dispose;

	function input_input_handler_1() {
		/*input_input_handler_1*/ ctx[8].call(input, /*row*/ ctx[11]);
	}

	return {
		c() {
			input = element("input");
			attr(input, "type", "number");
			attr(input, "id", input_id_value = "label-" + /*row*/ ctx[11].key);
			attr(input, "class", "form-control");
			input.required = input_required_value = /*row*/ ctx[11].required;
			attr(input, "placeholder", input_placeholder_value = /*row*/ ctx[11].placeholder);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*store*/ ctx[1][/*row*/ ctx[11].key]);

			if (!mounted) {
				dispose = listen(input, "input", input_input_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*store, rows*/ 6 && to_number(input.value) !== /*store*/ ctx[1][/*row*/ ctx[11].key]) {
				set_input_value(input, /*store*/ ctx[1][/*row*/ ctx[11].key]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (70:33) 
function create_if_block_2(ctx) {
	let input;
	let input_id_value;
	let input_required_value;
	let input_placeholder_value;
	let mounted;
	let dispose;

	function input_input_handler() {
		/*input_input_handler*/ ctx[7].call(input, /*row*/ ctx[11]);
	}

	return {
		c() {
			input = element("input");
			attr(input, "type", "text");
			attr(input, "id", input_id_value = "label-" + /*row*/ ctx[11].key);
			attr(input, "class", "form-control");
			input.required = input_required_value = /*row*/ ctx[11].required;
			attr(input, "placeholder", input_placeholder_value = /*row*/ ctx[11].placeholder);
			attr(input, "autocomplete", "off");
			attr(input, "autocorrect", "off");
			attr(input, "autocapitalize", "off");
			attr(input, "spellcheck", "false");
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*store*/ ctx[1][/*row*/ ctx[11].key]);

			if (!mounted) {
				dispose = listen(input, "input", input_input_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*store, rows*/ 6 && input.value !== /*store*/ ctx[1][/*row*/ ctx[11].key]) {
				set_input_value(input, /*store*/ ctx[1][/*row*/ ctx[11].key]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (57:3) {#if row.type === 'textarea'}
function create_if_block_1(ctx) {
	let textarea;
	let textarea_id_value;
	let textarea_rows_value;
	let textarea_required_value;
	let textarea_placeholder_value;
	let mounted;
	let dispose;

	function textarea_input_handler() {
		/*textarea_input_handler*/ ctx[6].call(textarea, /*row*/ ctx[11]);
	}

	return {
		c() {
			textarea = element("textarea");
			attr(textarea, "id", textarea_id_value = "label-" + /*row*/ ctx[11].key);
			attr(textarea, "rows", textarea_rows_value = /*row*/ ctx[11].rows || 3);
			attr(textarea, "class", "form-control");
			textarea.required = textarea_required_value = /*row*/ ctx[11].required;
			attr(textarea, "placeholder", textarea_placeholder_value = /*row*/ ctx[11].placeholder);
			attr(textarea, "autocomplete", "off");
			attr(textarea, "autocorrect", "off");
			attr(textarea, "autocapitalize", "off");
			attr(textarea, "spellcheck", "false");
		},
		m(target, anchor) {
			insert(target, textarea, anchor);
			set_input_value(textarea, /*store*/ ctx[1][/*row*/ ctx[11].key]);

			if (!mounted) {
				dispose = listen(textarea, "input", textarea_input_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*store, rows*/ 6) {
				set_input_value(textarea, /*store*/ ctx[1][/*row*/ ctx[11].key]);
			}
		},
		d(detaching) {
			if (detaching) detach(textarea);
			mounted = false;
			dispose();
		}
	};
}

// (102:3) {#if row.desc !== '' && row.key.length + row.desc.length > 50}
function create_if_block(ctx) {
	let div;
	let raw_value = /*row*/ ctx[11].desc.replace('\n', '<br/>') + "";

	return {
		c() {
			div = element("div");
			attr(div, "class", "fs-xs opacity-50 mt-1 ms-1");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			div.innerHTML = raw_value;
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (47:1) {#each rows as row (row.key)}
function create_each_block(key_1, ctx) {
	let div;
	let label;
	let html_tag;
	let raw_value = ucfirst(/*row*/ ctx[11].key.replace('\n', '<br/>')) + "";
	let t0;
	let label_for_value;
	let t1;
	let t2;
	let if_block0 = /*row*/ ctx[11].desc !== '' && /*row*/ ctx[11].key.length + /*row*/ ctx[11].desc.length <= 50 && create_if_block_5(ctx);

	function select_block_type(ctx, dirty) {
		if (/*row*/ ctx[11].type === 'textarea') return create_if_block_1;
		if (/*row*/ ctx[11].type === 'text') return create_if_block_2;
		if (/*row*/ ctx[11].type === 'number') return create_if_block_3;
		if (/*row*/ ctx[11].type === 'date') return create_if_block_4;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block1 = current_block_type && current_block_type(ctx);
	let if_block2 = /*row*/ ctx[11].desc !== '' && /*row*/ ctx[11].key.length + /*row*/ ctx[11].desc.length > 50 && create_if_block(ctx);

	return {
		key: key_1,
		first: null,
		c() {
			div = element("div");
			label = element("label");
			html_tag = new HtmlTag(false);
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			html_tag.a = t0;
			attr(label, "for", label_for_value = "label-" + /*row*/ ctx[11].key);
			attr(label, "class", "control-label opacity-75 mb-1");
			attr(div, "class", "mb-3");
			this.first = div;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			html_tag.m(raw_value, label);
			append(label, t0);
			if (if_block0) if_block0.m(label, null);
			append(div, t1);
			if (if_block1) if_block1.m(div, null);
			append(div, t2);
			if (if_block2) if_block2.m(div, null);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (/*row*/ ctx[11].desc !== '' && /*row*/ ctx[11].key.length + /*row*/ ctx[11].desc.length <= 50) if_block0.p(ctx, dirty);
			if (if_block1) if_block1.p(ctx, dirty);
			if (/*row*/ ctx[11].desc !== '' && /*row*/ ctx[11].key.length + /*row*/ ctx[11].desc.length > 50) if_block2.p(ctx, dirty);
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();

			if (if_block1) {
				if_block1.d();
			}

			if (if_block2) if_block2.d();
		}
	};
}

function create_fragment(ctx) {
	let form;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t;
	let input;
	let mounted;
	let dispose;
	let each_value = /*rows*/ ctx[2];
	const get_key = ctx => /*row*/ ctx[11].key;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			form = element("form");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			input = element("input");
			attr(input, "type", "submit");
			attr(input, "tabindex", "-1");
			attr(input, "class", "visually-hidden");
		},
		m(target, anchor) {
			insert(target, form, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(form, null);
				}
			}

			append(form, t);
			append(form, input);
			/*form_binding*/ ctx[10](form);

			if (!mounted) {
				dispose = listen(form, "submit", prevent_default(/*handleSubmit*/ ctx[3]));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*rows, store, ucfirst*/ 6) {
				each_value = /*rows*/ ctx[2];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, form, destroy_block, create_each_block, t, get_each_context);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(form);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			/*form_binding*/ ctx[10](null);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { data = [] } = $$props;
	let { callback } = $$props;
	let formEl;

	let rows = data.map(row => {
		if (row.type === undefined) {
			row.type = 'text';
		}

		if (row.placeholder === undefined) {
			row.placeholder = '';
		}

		if (row.value === undefined) {
			row.value = '';
		}

		if (row.desc === undefined) {
			row.desc = '';
		}

		if (row.required === undefined) {
			row.required = false;
		}

		return row;
	});

	let store = (() => {
		const obj = {};

		rows.map(row => {
			obj[row.key] = row.value;
		});

		return obj;
	})();

	const handleSubmit = e => {
		window.modal.callback();
	};

	onMount(() => {
		window.modal.update('', 'callback', () => {
			typeof callback && callback(store);
		});

		jQuery(formEl).find('input,textarea').eq(0).focus().select();
	});

	function textarea_input_handler(row) {
		store[row.key] = this.value;
		$$invalidate(1, store);
	}

	function input_input_handler(row) {
		store[row.key] = this.value;
		$$invalidate(1, store);
	}

	function input_input_handler_1(row) {
		store[row.key] = to_number(this.value);
		$$invalidate(1, store);
	}

	function input_input_handler_2(row) {
		store[row.key] = this.value;
		$$invalidate(1, store);
	}

	function form_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			formEl = $$value;
			$$invalidate(0, formEl);
		});
	}

	$$self.$$set = $$props => {
		if ('data' in $$props) $$invalidate(4, data = $$props.data);
		if ('callback' in $$props) $$invalidate(5, callback = $$props.callback);
	};

	return [
		formEl,
		store,
		rows,
		handleSubmit,
		data,
		callback,
		textarea_input_handler,
		input_input_handler,
		input_input_handler_1,
		input_input_handler_2,
		form_binding
	];
}

class ModalForm extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { data: 4, callback: 5 });
	}
}

export default ModalForm;