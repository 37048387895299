import jsonStringify from '~/tools/jsonStringify';
import utoa from '~/tools/utoa';

export default async function ftch(method, url, params = {}, callback) {
	const opts = {
		type: method === 'html' ? 'get' : method,
		url,
		data: undefined,
		dataType: method === 'html' ? 'html' : 'json',
		headers : {},
		timeout: 30000,
	};
	opts.data = {
		payload: utoa(params),
	};

	if (opts.data.payload.length / 1024 / 1024 > 3) {
		// 3MB
		window.ffb(
			'데이터가 너무 커서 저장을 진행할 수 없습니다',
			'error'
		);
		return {
			status: 'fail',
			result: [],
		};
	}

	const startTime = Math.floor(+new Date() / 1000);
	return await jQuery
		.ajax(opts)
		.then((res) => {
			if (method !== 'html' && res.status !== 'done') {
				if (res.status === 'login') {
					// if (typeof openLoginForm === 'function') {
					// 	openLoginForm();
					// }
					return false;
				} else if (
					res.status === 'fail' &&
					typeof res.message !== 'undefined' &&
					res.message !== ''
				) {
					setTimeout(() => {
						window.ffb(res.message, 'error', true);
					}, 10);
				}
			}
			if (typeof callback === 'function') {
				return callback(res);
			}
			return res;
		})
		.catch((e, res) => {
			console.log(
				'e',
				e.status,
				{
					elapsedTime: Math.floor(+new Date() / 1000) - startTime,
					page: location.href,
				},
				params,
				res
			);

			let errorUrl = url;
			console.error('ftchError', e.status, errorUrl);

			if (e.status === 401) {
				ffb(
					'권한이 없습니다. 새로고침 혹은 다시 로그인 하십시오.',
					'error'
				);
			}

			return {
				status: 'fail',
				result: [],
			};
		})
		.always(() => {
			// ui.handleAjaxing('remove', url);
		});
}
