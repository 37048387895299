import isEmpty from '~/tools/isEmpty';

function getOs() {
	var userAgent = window.navigator.userAgent,
		platform = window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		iosPlatforms = ['iPhone', 'iPad', 'iPod'],
		os = null;
  
	if (macosPlatforms.indexOf(platform) !== -1) {
	  os = 'osx';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
	  os = 'ios';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
	  os = 'win';
	} else if (/Android/.test(userAgent)) {
	  os = 'android';
	} else if (!os && /Linux/.test(platform)) {
	  os = 'linux';
	}
  
	return os;
  }
  
  window.glb.isTouch = (function is_touch_device4() {
	var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

	var mq = function (query) {
		return window.matchMedia(query).matches;
	};

	if (
		'ontouchstart' in window ||
		(window.DocumentTouch && document instanceof DocumentTouch)
	) {
		return true;
	}

	// include the 'heartz' as a way to have a non matching MQ to help terminate the join
	// https://git.io/vznFH
	var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
		''
	);
	return mq(query);
})();
document
	.getElementsByTagName('html')[0]
	.classList.add(window.glb.isTouch ? 'touch' : 'no-touch');

window.glb.setTitle = (title = '') => {
	title = !isEmpty(title) ? title : '제목없음';
	title = title.replace(/&amp;/, '&');
	const suffix = ` - ${window.conf.siteName}`;
	if (title.trim() !== window.conf.siteName) {
		document.title = `${title.replace(suffix, '')}${suffix}`;
	} else {
		document.title = title;
	}
}
window.glb.browser = () => {
	const userAgent = navigator.userAgent;
	let browserName;
	if (userAgent.match(/chrome|chromium|crios/i)) {
		browserName = "chrome";
	} else if (userAgent.match(/firefox|fxios/i)) {
		browserName = "firefox";
	} else if (userAgent.match(/safari/i)) {
		browserName = "safari";
	} else if (userAgent.match(/opr\//i)) {
		browserName = "opera";
	} else if (userAgent.match(/edg/i)) {
		browserName = "edge";
	}
	return browserName;
}

window.glb.os = getOs();
(function () {
	var htmlEl = document.getElementsByTagName('html')[0];
	htmlEl.classList.add(`browser-${window.glb.browser()}`);
	htmlEl.classList.add(`os-${window.glb.os}`);
	if (window.glb.os !== 'win') {
		htmlEl.classList.add('os-no-win');
	}
	htmlEl.classList.add(window.glb.isTouch ? 'touch' : 'no-touch');
})();

window.glb.focusFirst = () => {
	let $target;
	if (window.modal.mcl() > 0) {
		$target = jQuery(`#modal-wrapper > .modal[data-mcl=${window.modal.mcl()}]`);
	} else {
		$target = jQuery('body');
	}
	$target.find('select:visible, input:visible, textarea:visible').first().focus();
}	

