/* components/Modal.svelte generated by Svelte v3.58.0 */
import {
	HtmlTag,
	SvelteComponent,
	append,
	assign,
	attr,
	check_outros,
	construct_svelte_component,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	get_spread_object,
	get_spread_update,
	globals,
	group_outros,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	outro_and_destroy_block,
	run_all,
	safe_not_equal,
	set_data,
	space,
	stop_propagation,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

const { window: window_1 } = globals;
import { onMount } from 'svelte';
import Spinner from '~/components/Spinner.svelte';
import Html from '~/components/Html.svelte';
import ucfirst from '~/tools/ucfirst';
import ModalForm from './ModalForm.svelte';
import ModalSelector from './ModalSelector.svelte';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[21] = list[i];
	child_ctx[23] = i;
	return child_ctx;
}

// (397:6) {:else}
function create_else_block(ctx) {
	let html_tag;
	let raw_value = /*obj*/ ctx[21].content + "";
	let html_anchor;

	return {
		c() {
			html_tag = new HtmlTag(false);
			html_anchor = empty();
			html_tag.a = html_anchor;
		},
		m(target, anchor) {
			html_tag.m(raw_value, target, anchor);
			insert(target, html_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*rows*/ 1 && raw_value !== (raw_value = /*obj*/ ctx[21].content + "")) html_tag.p(raw_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(html_anchor);
			if (detaching) html_tag.d();
		}
	};
}

// (395:60) 
function create_if_block_4(ctx) {
	let html;
	let current;
	html = new Html({ props: { url: /*obj*/ ctx[21].content } });

	return {
		c() {
			create_component(html.$$.fragment);
		},
		m(target, anchor) {
			mount_component(html, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const html_changes = {};
			if (dirty & /*rows*/ 1) html_changes.url = /*obj*/ ctx[21].content;
			html.$set(html_changes);
		},
		i(local) {
			if (current) return;
			transition_in(html.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(html.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(html, detaching);
		}
	};
}

// (388:6) {#if typeof obj.content !== 'string'}
function create_if_block_3(ctx) {
	let switch_instance;
	let switch_instance_anchor;
	let current;
	const switch_instance_spread_levels = [{ time: /*obj*/ ctx[21].time }, /*obj*/ ctx[21].props];
	var switch_value = /*obj*/ ctx[21].content;

	function switch_props(ctx) {
		let switch_instance_props = {};

		for (let i = 0; i < switch_instance_spread_levels.length; i += 1) {
			switch_instance_props = assign(switch_instance_props, switch_instance_spread_levels[i]);
		}

		return { props: switch_instance_props };
	}

	if (switch_value) {
		switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance.$on("submit", function () {
			if (is_function(/*obj*/ ctx[21].handleSubmit)) /*obj*/ ctx[21].handleSubmit.apply(this, arguments);
		});
	}

	return {
		c() {
			if (switch_instance) create_component(switch_instance.$$.fragment);
			switch_instance_anchor = empty();
		},
		m(target, anchor) {
			if (switch_instance) mount_component(switch_instance, target, anchor);
			insert(target, switch_instance_anchor, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			const switch_instance_changes = (dirty & /*rows*/ 1)
			? get_spread_update(switch_instance_spread_levels, [{ time: /*obj*/ ctx[21].time }, get_spread_object(/*obj*/ ctx[21].props)])
			: {};

			if (dirty & /*rows*/ 1 && switch_value !== (switch_value = /*obj*/ ctx[21].content)) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance.$on("submit", function () {
						if (is_function(/*obj*/ ctx[21].handleSubmit)) /*obj*/ ctx[21].handleSubmit.apply(this, arguments);
					});

					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, switch_instance_anchor.parentNode, switch_instance_anchor);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				switch_instance.$set(switch_instance_changes);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(switch_instance_anchor);
			if (switch_instance) destroy_component(switch_instance, detaching);
		}
	};
}

// (401:5) {#if obj.viewFooter}
function create_if_block_1(ctx) {
	let div2;
	let div0;
	let button;
	let t0_value = ucfirst(/*obj*/ ctx[21].okLabel) + "";
	let t0;
	let button_autofocus_value;
	let button_disabled_value;
	let t1;
	let t2;
	let div1;
	let mounted;
	let dispose;
	let if_block = /*obj*/ ctx[21].cancelLabel !== '' && create_if_block_2(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			button = element("button");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			div1 = element("div");
			attr(button, "type", "button");
			attr(button, "class", "btn btn-primary r");
			button.autofocus = button_autofocus_value = true;
			button.disabled = button_disabled_value = /*obj*/ ctx[21].isBlock;
			attr(div0, "class", "d-flex");
			attr(div1, "class", "modal-footer-spare");
			attr(div2, "class", "modal-footer px-3 px-sm-4");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, button);
			append(button, t0);
			append(div0, t1);
			if (if_block) if_block.m(div0, null);
			append(div2, t2);
			append(div2, div1);
			if (true) button.focus();

			if (!mounted) {
				dispose = listen(button, "click", stop_propagation(/*handleOk*/ ctx[2]));
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*rows*/ 1 && t0_value !== (t0_value = ucfirst(/*obj*/ ctx[21].okLabel) + "")) set_data(t0, t0_value);

			if (dirty & /*rows*/ 1 && button_disabled_value !== (button_disabled_value = /*obj*/ ctx[21].isBlock)) {
				button.disabled = button_disabled_value;
			}

			if (/*obj*/ ctx[21].cancelLabel !== '') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

// (412:8) {#if obj.cancelLabel !== ''}
function create_if_block_2(ctx) {
	let button;
	let t_value = ucfirst(/*obj*/ ctx[21].cancelLabel) + "";
	let t;
	let button_disabled_value;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "type", "button");
			attr(button, "class", "btn btn-link r");
			button.disabled = button_disabled_value = /*obj*/ ctx[21].isBlock;
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", stop_propagation(/*close*/ ctx[1].bind(null, false)));
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*rows*/ 1 && t_value !== (t_value = ucfirst(/*obj*/ ctx[21].cancelLabel) + "")) set_data(t, t_value);

			if (dirty & /*rows*/ 1 && button_disabled_value !== (button_disabled_value = /*obj*/ ctx[21].isBlock)) {
				button.disabled = button_disabled_value;
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (429:3) {#if obj.isBlock}
function create_if_block(ctx) {
	let spinner;
	let current;

	spinner = new Spinner({
			props: {
				style: !/*obj*/ ctx[21].viewFooter ? `margin-top: -10px` : ''
			}
		});

	return {
		c() {
			create_component(spinner.$$.fragment);
		},
		m(target, anchor) {
			mount_component(spinner, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const spinner_changes = {};
			if (dirty & /*rows*/ 1) spinner_changes.style = !/*obj*/ ctx[21].viewFooter ? `margin-top: -10px` : '';
			spinner.$set(spinner_changes);
		},
		i(local) {
			if (current) return;
			transition_in(spinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(spinner, detaching);
		}
	};
}

// (345:1) {#each rows as obj, i (obj.id)}
function create_each_block(key_1, ctx) {
	let div5;
	let div0;
	let t0;
	let div4;
	let div3;
	let div1;
	let h5;
	let t1_value = /*obj*/ ctx[21].title + "";
	let t1;
	let t2;
	let button;
	let t3;
	let div2;
	let show_if;
	let current_block_type_index;
	let if_block0;
	let div2_style_value;
	let t4;
	let div3_style_value;
	let div4_class_value;
	let t5;
	let t6;
	let div5_id_value;
	let div5_data_mcl_value;
	let div5_class_value;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block_3, create_if_block_4, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (dirty & /*rows*/ 1) show_if = null;
		if (typeof /*obj*/ ctx[21].content !== 'string') return 0;
		if (show_if == null) show_if = !!(/^\/[a-z0-9_\-\/\?&=]+$/i).test(/*obj*/ ctx[21].content);
		if (show_if) return 1;
		return 2;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let if_block1 = /*obj*/ ctx[21].viewFooter && create_if_block_1(ctx);
	let if_block2 = /*obj*/ ctx[21].isBlock && create_if_block(ctx);

	return {
		key: key_1,
		first: null,
		c() {
			div5 = element("div");
			div0 = element("div");
			t0 = space();
			div4 = element("div");
			div3 = element("div");
			div1 = element("div");
			h5 = element("h5");
			t1 = text(t1_value);
			t2 = space();
			button = element("button");
			t3 = space();
			div2 = element("div");
			if_block0.c();
			t4 = space();
			if (if_block1) if_block1.c();
			t5 = space();
			if (if_block2) if_block2.c();
			t6 = space();
			attr(div0, "class", "modal-overlay");
			attr(h5, "class", "modal-title");
			toggle_class(h5, "ms-4", !/*obj*/ ctx[21].closeOnBack);
			attr(button, "type", "button");
			attr(button, "class", "btn-close");
			toggle_class(button, "d-none", !/*obj*/ ctx[21].closeOnBack);
			attr(div1, "class", "modal-header");
			attr(div2, "class", "modal-body p-3 p-sm-4");
			attr(div2, "style", div2_style_value = `visibility: ${/*obj*/ ctx[21].isBlock ? 'hidden' : 'visible'}`);
			attr(div3, "class", "modal-content shadow");
			attr(div3, "style", div3_style_value = `margin-left:${5 * /*i*/ ctx[23] + 10}px;margin-right:${5 * /*i*/ ctx[23] + 10}px;`);
			attr(div4, "class", div4_class_value = `modal-dialog modal-dialog-centered modal-dialog-scrollable modal-${/*obj*/ ctx[21].size}`);
			attr(div5, "id", div5_id_value = `modal-${/*obj*/ ctx[21].id}`);
			attr(div5, "data-mcl", div5_data_mcl_value = /*i*/ ctx[23] + 1);
			attr(div5, "class", div5_class_value = `modal d-block ${/*obj*/ ctx[21].class}`);
			attr(div5, "tabindex", "-1");
			this.first = div5;
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div0);
			append(div5, t0);
			append(div5, div4);
			append(div4, div3);
			append(div3, div1);
			append(div1, h5);
			append(h5, t1);
			append(div1, t2);
			append(div1, button);
			append(div3, t3);
			append(div3, div2);
			if_blocks[current_block_type_index].m(div2, null);
			append(div3, t4);
			if (if_block1) if_block1.m(div3, null);
			append(div5, t5);
			if (if_block2) if_block2.m(div5, null);
			append(div5, t6);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div0, "click", function () {
						if (is_function(/*obj*/ ctx[21].closeOnBack
						? /*click_handler*/ ctx[4]
						: null)) (/*obj*/ ctx[21].closeOnBack
						? /*click_handler*/ ctx[4]
						: null).apply(this, arguments);
					}),
					listen(button, "click", stop_propagation(/*close*/ ctx[1].bind(null, false)))
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if ((!current || dirty & /*rows*/ 1) && t1_value !== (t1_value = /*obj*/ ctx[21].title + "")) set_data(t1, t1_value);

			if (!current || dirty & /*rows*/ 1) {
				toggle_class(h5, "ms-4", !/*obj*/ ctx[21].closeOnBack);
			}

			if (!current || dirty & /*rows*/ 1) {
				toggle_class(button, "d-none", !/*obj*/ ctx[21].closeOnBack);
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				} else {
					if_block0.p(ctx, dirty);
				}

				transition_in(if_block0, 1);
				if_block0.m(div2, null);
			}

			if (!current || dirty & /*rows*/ 1 && div2_style_value !== (div2_style_value = `visibility: ${/*obj*/ ctx[21].isBlock ? 'hidden' : 'visible'}`)) {
				attr(div2, "style", div2_style_value);
			}

			if (/*obj*/ ctx[21].viewFooter) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div3, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (!current || dirty & /*rows*/ 1 && div3_style_value !== (div3_style_value = `margin-left:${5 * /*i*/ ctx[23] + 10}px;margin-right:${5 * /*i*/ ctx[23] + 10}px;`)) {
				attr(div3, "style", div3_style_value);
			}

			if (!current || dirty & /*rows*/ 1 && div4_class_value !== (div4_class_value = `modal-dialog modal-dialog-centered modal-dialog-scrollable modal-${/*obj*/ ctx[21].size}`)) {
				attr(div4, "class", div4_class_value);
			}

			if (/*obj*/ ctx[21].isBlock) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*rows*/ 1) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div5, t6);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*rows*/ 1 && div5_id_value !== (div5_id_value = `modal-${/*obj*/ ctx[21].id}`)) {
				attr(div5, "id", div5_id_value);
			}

			if (!current || dirty & /*rows*/ 1 && div5_data_mcl_value !== (div5_data_mcl_value = /*i*/ ctx[23] + 1)) {
				attr(div5, "data-mcl", div5_data_mcl_value);
			}

			if (!current || dirty & /*rows*/ 1 && div5_class_value !== (div5_class_value = `modal d-block ${/*obj*/ ctx[21].class}`)) {
				attr(div5, "class", div5_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			if_blocks[current_block_type_index].d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let current;
	let mounted;
	let dispose;
	let each_value = /*rows*/ ctx[0];
	const get_key = ctx => /*obj*/ ctx[21].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "id", "modal-wrapper");
			attr(div, "class", "posidtion-fixed");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;

			if (!mounted) {
				dispose = listen(window_1, "keyup", /*handleKeyUp*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*rows, close, ucfirst, handleOk*/ 7) {
				each_value = /*rows*/ ctx[0];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, outro_and_destroy_block, create_each_block, null, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let rows = [];
	let id = 0;
	const frequentlyMsg = { delete: '정말로 삭제하시겠습니까?' };

	const open = (obj = {}) => {
		if (obj.id !== undefined && exist(obj.id)) {
			Object.keys(obj).forEach(k => {
				update(obj.id, k, obj[k]);
			});

			return false;
		}

		obj.id = obj.id || `modal-${id++}`;
		obj.isBlock = obj.isBlock || false;
		obj.title = obj.title || '';
		obj.props = obj.props || {};
		obj.size = typeof obj.size !== 'undefined' ? obj.size : '';
		obj.content = obj.content || 'content';

		if (typeof obj.content === 'string' && typeof frequentlyMsg[obj.content] !== 'undefined') {
			obj.content = frequentlyMsg[obj.content];
		}

		obj.okLabel = obj.okLabel !== undefined ? obj.okLabel : '';
		obj.class = obj.class !== undefined ? obj.class : '';

		if (obj.okLabel === '') {
			obj.okLabel = '확인';
		}

		obj.autoFocus = obj.autoFocus !== undefined ? obj.autoFocus : true;
		obj.cancelLabel = obj.cancelLabel !== undefined ? obj.cancelLabel : '취소';
		obj.viewFooter = obj.viewFooter !== undefined ? obj.viewFooter : true;
		obj.closeOnBack = obj.closeOnBack !== undefined ? obj.closeOnBack : true;
		$$invalidate(0, rows = [...rows, obj]);

		if (obj.autoFocus) {
			const mcl = window.modal.mcl();

			setTimeout(
				() => {
					if (mcl === window.modal.mcl()) {
						const firstControl = jQuery('.modal-content').last().find('input, textarea, select').eq(0);

						if (firstControl.length === 1) {
							jQuery(firstControl).select().focus();
						} else {
							// confirm 버튼 focus
							jQuery('.modal-footer').last().find('.btn-primary').eq(0).focus();
						}
					}
				},
				50
			);
		}

		setTimeout(
			() => {
				jQuery('.modal-overlay').last().addClass('shown');
				jQuery('.modal-content').last().addClass('shown');
			},
			1
		);
	};

	const update = (modalId = '', key, value) => {
		let index = -1;

		if (modalId === '') {
			index = rows.length - 1;
		} else {
			index = rows.findIndex(r => r.id === modalId);
		}

		if (index > -1) {
			$$invalidate(0, rows[index][key] = value, rows);
		}
	};

	const close = (forceClose = false) => {
		const obj = rows[rows.length - 1];

		if (obj === undefined) {
			return false;
		}

		if (!forceClose && typeof obj.cancelCallback === 'function') {
			obj.cancelCallback();
		} else {
			jQuery('.modal-overlay').last().removeClass('shown');
			jQuery('.modal-content').last().removeClass('shown');

			// setTimeout(() => {
			rows.pop();

			$$invalidate(0, rows);
		} // }, 250);
	};

	const scrollTop = (top = 0) => {
		// confirm 버튼 focus
		jQuery('.modal-body').last().scrollTop(top);
	};

	const callback = () => {
		const obj = rows[rows.length - 1];

		if (obj === undefined) {
			return false;
		}

		typeof obj.callback === 'function' && obj.callback();
	};

	const cancelCallback = () => {
		const obj = rows[rows.length - 1];

		if (obj === undefined) {
			return false;
		}

		typeof obj.cancelCallback === 'function' && obj.cancelCallback();
	};

	const alert = (content = '', callback, closeOnBack = true) => {
		open({
			size: 'xs',
			title: '알람',
			content,
			cancelLabel: '',
			closeOnBack,
			callback: () => {
				close(true);

				setTimeout(
					() => {
						typeof callback === 'function' && callback();
					},
					10
				);
			}
		});
	};

	const selector = (modalOpts = {}) => {
		open({
			size: modalOpts.size || 'xxs',
			title: modalOpts.title || '선택',
			content: ModalSelector,
			autoFocus: modalOpts.autoFocus || false,
			props: {
				options: modalOpts.options || {},
				desc: modalOpts.desc || '',
				value: modalOpts.value || '',
				callback: modalOpts.callback,
				cancelCallback: modalOpts.cancelCallback
			}
		});
	};

	const confirm = (content, callback, cancelCallback, okLabel, cancelLabel, autoClose = true) => {
		if (content === null) {
			typeof callback === 'function' && callback();
			return;
		}

		open({
			size: 'xs',
			title: '확인',
			content,
			okLabel,
			cancelLabel,
			callback: () => {
				if (autoClose) {
					close(true);
				} else {
					block('add');
				}

				if (typeof callback === 'function') {
					setTimeout(
						() => {
							typeof callback === 'function' && callback();
						},
						10
					);
				}
			},
			cancelCallback: () => {
				close(true);

				if (typeof cancelCallback === 'function') {
					setTimeout(
						() => {
							typeof cancelCallback === 'function' && cancelCallback();
						},
						10
					);
				}
			}
		});
	};

	const prompt = (content = '', value = '', callback, rows = 1, userOpts = {}) => {
		form(
			'입력',
			[
				{
					key: content,
					value,
					type: rows === 1 ? 'text' : 'textarea',
					rows
				}
			],
			obj => {
				window.modal.close(true);
				typeof callback === 'function' && callback(obj[content]);
			},
			userOpts
		);
	};

	/**
 *
 window.modal.form(title, [{
 	key: 'total',
	value: 0,
	type: 'text', // or textarea
	placeholder: '',
	desc: '' 
 }, () => {}, {});
 * @param title
 * @param data
 * @param callback
 * @param userOpts
 */
	const form = (title = '', data = [], callback, userOpts = {}) => {
		const opts = {
			size: 'xs',
			title,
			content: ModalForm,
			props: { data, callback }
		};

		Object.entries(userOpts).forEach(([k, v]) => {
			opts[k] = v;
		});

		open(opts);
	};

	const exist = (modalId = '') => {
		return rows.findIndex(r => r.id === modalId) > -1;
	};

	const handleBack = e => {
		if (e.target.classList.contains('modal-overlay')) {
			e.preventDefault();
			close(true);
		}
	};

	const handleOk = () => {
		const btnSubmit = jQuery('.modal-body').last().children('form').first().find('[type=submit]');

		if (btnSubmit.length > 0) {
			btnSubmit[0].click();
			return;
		}

		const i = rows.length - 1;
		const obj = rows[i];

		if (typeof obj.callback === 'function') {
			obj.callback();
		} else {
			close(true);
		}
	};

	// 현재 모달의 인덱스
	const mcl = () => {
		return rows.length;
	};

	const block = (method = '') => {
		const targetIndex = rows.length - 1;

		switch (method) {
			case 'toggle':
				{
					$$invalidate(0, rows[targetIndex].isBlock = !rows[targetIndex].isBlock, rows);
					break;
				}
			case 'remove':
				{
					$$invalidate(0, rows[targetIndex].isBlock = false, rows);
					break;
				}
			case 'add':
				{
					$$invalidate(0, rows[targetIndex].isBlock = true, rows);
					break;
				}
			default:
				{
					// check
					return rows[targetIndex].isBlock;
				}
		}
	};

	const handleKeyUp = e => {
		if (e.keyCode === 27 && !window.glb.eyeDropperOpened) {
			close();
		}
	};

	onMount(() => {
		window.modal = {
			mcl,
			alert,
			open,
			close,
			confirm,
			prompt,
			form,
			selector,
			update,
			exist,
			block,
			scrollTop,
			callback
		};
	});

	const click_handler = () => {
		close();
	};

	return [rows, close, handleOk, handleKeyUp, click_handler];
}

class Modal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Modal;