/* pages/Main.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Moim from './Moim.svelte';
import Transactions from './Transactions.svelte';
import PwaPopup from '~/components/PwaPopup.svelte';
import PullToRefreshOnIos from '~/components/PullToRefreshOnIos.svelte';

function create_fragment(ctx) {
	let moim;
	let t0;
	let div0;
	let t1;
	let transactions;
	let t2;
	let div1;
	let t4;
	let pwapopup;
	let t5;
	let pulltorefreshonios;
	let current;
	moim = new Moim({ props: { slug: /*slug*/ ctx[0] } });
	transactions = new Transactions({ props: { slug: /*slug*/ ctx[0] } });
	pwapopup = new PwaPopup({});

	pulltorefreshonios = new PullToRefreshOnIos({
			props: {
				onlyInPwa: true,
				callback: /*func*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(moim.$$.fragment);
			t0 = space();
			div0 = element("div");
			t1 = space();
			create_component(transactions.$$.fragment);
			t2 = space();
			div1 = element("div");

			div1.innerHTML = `<a class="btn btn-link text-decoration-none" href="/" style="font-size:0.9em;color:gray;"><i class="bi bi-house"></i>
		초기화면</a>`;

			t4 = space();
			create_component(pwapopup.$$.fragment);
			t5 = space();
			create_component(pulltorefreshonios.$$.fragment);
			attr(div0, "class", "my-5");
			attr(div1, "class", "py-3 text-center");
		},
		m(target, anchor) {
			mount_component(moim, target, anchor);
			insert(target, t0, anchor);
			insert(target, div0, anchor);
			insert(target, t1, anchor);
			mount_component(transactions, target, anchor);
			insert(target, t2, anchor);
			insert(target, div1, anchor);
			insert(target, t4, anchor);
			mount_component(pwapopup, target, anchor);
			insert(target, t5, anchor);
			mount_component(pulltorefreshonios, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(moim.$$.fragment, local);
			transition_in(transactions.$$.fragment, local);
			transition_in(pwapopup.$$.fragment, local);
			transition_in(pulltorefreshonios.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(moim.$$.fragment, local);
			transition_out(transactions.$$.fragment, local);
			transition_out(pwapopup.$$.fragment, local);
			transition_out(pulltorefreshonios.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(moim, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			destroy_component(transactions, detaching);
			if (detaching) detach(t2);
			if (detaching) detach(div1);
			if (detaching) detach(t4);
			destroy_component(pwapopup, detaching);
			if (detaching) detach(t5);
			destroy_component(pulltorefreshonios, detaching);
		}
	};
}

function instance($$self) {
	const slug = window.__PROPS.slug;

	const func = () => {
		window.blockPage('add');
		location.reload();
	};

	return [slug, func];
}

class Main extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Main;