/* pages/MoimHelp.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

function create_fragment(ctx) {
	let p0;
	let t1;
	let h40;
	let t3;
	let p1;
	let t5;
	let h41;
	let t7;
	let p2;
	let t9;
	let p3;
	let t11;
	let h42;
	let t13;
	let p4;
	let t15;
	let h43;
	let t17;
	let p5;
	let t19;
	let h44;
	let t21;
	let p6;
	let t23;
	let h45;
	let t25;
	let p7;
	let t27;
	let h46;
	let t29;
	let p8;
	let t31;
	let h47;
	let t33;
	let p9;
	let t35;
	let h48;
	let t37;
	let p10;
	let t39;
	let h49;
	let t41;
	let p11;

	return {
		c() {
			p0 = element("p");
			p0.textContent = "모임에 오신 것을 환영합니다. 어차피 자주 만나는데 만날 때마다 n빵(더치페이)를 하는것이 번거롭고, 모임비를 만들면 참석 못하는 사람이 손해를 보는 구조를 해결하기 위해 만들어졌습니다.\n\t돌아가면서 결제하는데 얼마를 누가 했는지, 누가 참석했는지를 기록하면 자동으로 모든것이 처리됩니다.\n\t두명부터 10명 20명까지 모든 구성원에 대하여 가능합니다.";
			t1 = space();
			h40 = element("h4");
			h40.textContent = "구성원별 금액";
			t3 = space();
			p1 = element("p");
			p1.textContent = "상단의 구성원별 금액은 현재까지 누가 얼마를 냈고, 얼마를 덜 냈는지 표시하는 곳입니다.\n\t마이너스는 그만큼 내서 메꾸어야할 금액을 의미하고, 플러스는 많이 낸 만큼을 표시합니다.\n\t결제타이밍이 도래했다면 마이너스 금액이 큰 사람이 내는것이 적합합니다.";
			t5 = space();
			h41 = element("h4");
			h41.textContent = "모임 생성";
			t7 = space();
			p2 = element("p");
			p2.textContent = "초기화면에서 \"모임 생성\"을 눌러 새로운 모임을 생성할 수 있습니다.\n\t생성된 모임은 고유의 주소를 가지며 이 주소를 알면 누구나 들어와서 관리자와 동일한 권한을 갖게 됩니다.";
			t9 = space();
			p3 = element("p");
			p3.textContent = "만약 구성원 전체가 신뢰할만하다면 카카오톡의 공지 등으로 주소를 공지하여, 누구나 참여할 수 있게 할 수 있습니다.\n\t반대로 구성원 전체를 신뢰할 수 없다면 본인만 주소를 즐겨찾기해두고 관리하십시오. 구성원에게는 결과만 공유하시면 됩니다.";
			t11 = space();
			h42 = element("h4");
			h42.textContent = "모임 (구성원)수정";
			t13 = space();
			p4 = element("p");
			p4.textContent = "모임의 설정에서 모임명과 구성원을 수정할 수 있습니다. 인원제한은 없으나 실제 사용상황을 생각하면 구성원은 10명 이내가 적합하지 않을까 생각합니다.\n\t구성원으로 등록되면 결제시 선택가능해집니다.";
			t15 = space();
			h43 = element("h4");
			h43.textContent = "모임 삭제";
			t17 = space();
			p5 = element("p");
			p5.textContent = "모임은 언제든지 삭제할 수 있습니다. 하지만 빌런으로 인한 삭제나 실수삭제를 막기 위하여, 삭제요청 후 일주일 간은 삭제상태로 유지됩니다.\n\t누구든지 삭제를 취소하면 삭제요청은 취소됩니다.";
			t19 = space();
			h44 = element("h4");
			h44.textContent = "거래 등록";
			t21 = space();
			p6 = element("p");
			p6.textContent = "참여 구성원과 결제자를 선택하고 입력하면 됩니다. 구성원은 모임의 수정에서 할 수 있습니다.";
			t23 = space();
			h45 = element("h4");
			h45.textContent = "거래 취소";
			t25 = space();
			p7 = element("p");
			p7.textContent = "조작을 막기 위하여 모든 거래들은 은행의 거래내역처럼 수정이나 삭제 없이 등록만 가능합니다.\n\t만약 이미 등록된 거래를 삭제하려면, 거래의 우측 상단 ▼ 버튼을 눌러 취소거래를 등록할 수 있습니다.";
			t27 = space();
			h46 = element("h4");
			h46.textContent = "초기값 등록";
			t29 = space();
			p8 = element("p");
			p8.textContent = "모임을 시작하는 시점에서 이미 기존에 거래된 것이 있을 수 있습니다.\n\t이런 초기설정도 특별한 것은 없습니다. 모든 구성원을 참여시킨 이후에 결제도 모든 구성원을 선택하시고 각 구성원이 지금까지 낸 금액을 입력해주시면 됩니다.";
			t31 = space();
			h47 = element("h4");
			h47.textContent = "홈화면 앱 등록";
			t33 = space();
			p9 = element("p");
			p9.textContent = "기본적으로 카카오톡의 단톡방 공지로 등록해놓으면 좋습니다. 또한 즐겨찾기도 유용합니다.\n\t그 중에서 가장 유용한 방법은 홈화면에 등록하는 것입니다.\n\t아이폰의 경우 사파리, 안드로이드의 경우 크롬으로 접속하면 홈화면 등록을 할 수 있습니다.";
			t35 = space();
			h48 = element("h4");
			h48.textContent = "데이터 보관기간";
			t37 = space();
			p10 = element("p");
			p10.textContent = "모임에 신규 거래가 3년 이상 없는 경우에는 자동으로 삭제됩니다.";
			t39 = space();
			h49 = element("h4");
			h49.textContent = "기타";
			t41 = space();
			p11 = element("p");
			p11.textContent = "제안사항 및 문제점은 support@whooing.com 으로 연락주시면 됩니다.";
			attr(p0, "class", "svelte-muu0nx");
			attr(h40, "class", "svelte-muu0nx");
			attr(p1, "class", "svelte-muu0nx");
			attr(h41, "class", "svelte-muu0nx");
			attr(p2, "class", "svelte-muu0nx");
			attr(p3, "class", "svelte-muu0nx");
			attr(h42, "class", "svelte-muu0nx");
			attr(p4, "class", "svelte-muu0nx");
			attr(h43, "class", "svelte-muu0nx");
			attr(p5, "class", "svelte-muu0nx");
			attr(h44, "class", "svelte-muu0nx");
			attr(p6, "class", "svelte-muu0nx");
			attr(h45, "class", "svelte-muu0nx");
			attr(p7, "class", "svelte-muu0nx");
			attr(h46, "class", "svelte-muu0nx");
			attr(p8, "class", "svelte-muu0nx");
			attr(h47, "class", "svelte-muu0nx");
			attr(p9, "class", "svelte-muu0nx");
			attr(h48, "class", "svelte-muu0nx");
			attr(p10, "class", "svelte-muu0nx");
			attr(h49, "class", "svelte-muu0nx");
			attr(p11, "class", "svelte-muu0nx");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, h40, anchor);
			insert(target, t3, anchor);
			insert(target, p1, anchor);
			insert(target, t5, anchor);
			insert(target, h41, anchor);
			insert(target, t7, anchor);
			insert(target, p2, anchor);
			insert(target, t9, anchor);
			insert(target, p3, anchor);
			insert(target, t11, anchor);
			insert(target, h42, anchor);
			insert(target, t13, anchor);
			insert(target, p4, anchor);
			insert(target, t15, anchor);
			insert(target, h43, anchor);
			insert(target, t17, anchor);
			insert(target, p5, anchor);
			insert(target, t19, anchor);
			insert(target, h44, anchor);
			insert(target, t21, anchor);
			insert(target, p6, anchor);
			insert(target, t23, anchor);
			insert(target, h45, anchor);
			insert(target, t25, anchor);
			insert(target, p7, anchor);
			insert(target, t27, anchor);
			insert(target, h46, anchor);
			insert(target, t29, anchor);
			insert(target, p8, anchor);
			insert(target, t31, anchor);
			insert(target, h47, anchor);
			insert(target, t33, anchor);
			insert(target, p9, anchor);
			insert(target, t35, anchor);
			insert(target, h48, anchor);
			insert(target, t37, anchor);
			insert(target, p10, anchor);
			insert(target, t39, anchor);
			insert(target, h49, anchor);
			insert(target, t41, anchor);
			insert(target, p11, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(h40);
			if (detaching) detach(t3);
			if (detaching) detach(p1);
			if (detaching) detach(t5);
			if (detaching) detach(h41);
			if (detaching) detach(t7);
			if (detaching) detach(p2);
			if (detaching) detach(t9);
			if (detaching) detach(p3);
			if (detaching) detach(t11);
			if (detaching) detach(h42);
			if (detaching) detach(t13);
			if (detaching) detach(p4);
			if (detaching) detach(t15);
			if (detaching) detach(h43);
			if (detaching) detach(t17);
			if (detaching) detach(p5);
			if (detaching) detach(t19);
			if (detaching) detach(h44);
			if (detaching) detach(t21);
			if (detaching) detach(p6);
			if (detaching) detach(t23);
			if (detaching) detach(h45);
			if (detaching) detach(t25);
			if (detaching) detach(p7);
			if (detaching) detach(t27);
			if (detaching) detach(h46);
			if (detaching) detach(t29);
			if (detaching) detach(p8);
			if (detaching) detach(t31);
			if (detaching) detach(h47);
			if (detaching) detach(t33);
			if (detaching) detach(p9);
			if (detaching) detach(t35);
			if (detaching) detach(h48);
			if (detaching) detach(t37);
			if (detaching) detach(p10);
			if (detaching) detach(t39);
			if (detaching) detach(h49);
			if (detaching) detach(t41);
			if (detaching) detach(p11);
		}
	};
}

class MoimHelp extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default MoimHelp;