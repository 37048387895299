/* pages/MoimForm.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_block,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_input_value,
	space,
	update_keyed_each
} from "svelte/internal";

import { onMount, tick } from "svelte";
import moims from '~/stores/moims';
import { uniq } from 'lodash';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[12] = list[i];
	child_ctx[13] = list;
	child_ctx[14] = i;
	return child_ctx;
}

// (83:2) {#each users as user (user.id)}
function create_each_block(key_1, ctx) {
	let div;
	let span;
	let t;
	let input;
	let mounted;
	let dispose;

	function input_input_handler_1() {
		/*input_input_handler_1*/ ctx[7].call(input, /*each_value*/ ctx[13], /*user_index*/ ctx[14]);
	}

	return {
		key: key_1,
		first: null,
		c() {
			div = element("div");
			span = element("span");
			span.innerHTML = `<i class="bi bi-trash3"></i>`;
			t = space();
			input = element("input");
			attr(span, "class", "btn btn-secondary-outline svelte-1e0c9l6");
			attr(input, "type", "text");
			attr(input, "class", "form-control");
			attr(div, "class", "user-row mb-2 svelte-1e0c9l6");
			this.first = div;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			append(div, t);
			append(div, input);
			set_input_value(input, /*user*/ ctx[12].name);

			if (!mounted) {
				dispose = [
					listen(span, "click", function () {
						if (is_function(/*handleRemove*/ ctx[4].bind(null, /*user*/ ctx[12].id))) /*handleRemove*/ ctx[4].bind(null, /*user*/ ctx[12].id).apply(this, arguments);
					}),
					listen(input, "input", input_input_handler_1),
					listen(input, "keyup", keyup_handler_1)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*users*/ 2 && input.value !== /*user*/ ctx[12].name) {
				set_input_value(input, /*user*/ ctx[12].name);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let form;
	let div0;
	let label0;
	let t1;
	let input;
	let t2;
	let div2;
	let label1;
	let t4;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t5;
	let div1;
	let span;
	let mounted;
	let dispose;
	let each_value = /*users*/ ctx[1];
	const get_key = ctx => /*user*/ ctx[12].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			form = element("form");
			div0 = element("div");
			label0 = element("label");

			label0.innerHTML = `<i class="bi bi-tag"></i>
			모임명`;

			t1 = space();
			input = element("input");
			t2 = space();
			div2 = element("div");
			label1 = element("label");

			label1.innerHTML = `<i class="bi bi-people"></i>
			구성원`;

			t4 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t5 = space();
			div1 = element("div");
			span = element("span");

			span.innerHTML = `<i class="bi bi-plus-lg"></i>
				구성원 추가`;

			attr(label0, "class", "control-label mb-1");
			attr(input, "placeholder", "무명의 모임");
			attr(input, "type", "text");
			attr(input, "class", "form-control");
			attr(div0, "class", "mb-3");
			attr(label1, "class", "control-label mb-1");
			attr(span, "class", "d-inline-block btn btn-secondary-outline svelte-1e0c9l6");
			attr(div1, "class", "text-center");
			attr(div2, "class", "mb-3");
			attr(form, "id", "moim-form");
			attr(form, "class", "svelte-1e0c9l6");
		},
		m(target, anchor) {
			insert(target, form, anchor);
			append(form, div0);
			append(div0, label0);
			append(div0, t1);
			append(div0, input);
			set_input_value(input, /*name*/ ctx[0]);
			append(form, t2);
			append(form, div2);
			append(div2, label1);
			append(div2, t4);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div2, null);
				}
			}

			append(div2, t5);
			append(div2, div1);
			append(div1, span);

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[6]),
					listen(input, "keyup", keyup_handler),
					listen(span, "click", prevent_default(/*handleAdd*/ ctx[3])),
					listen(form, "submit", prevent_default(/*handleSubmit*/ ctx[2]))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*name*/ 1 && input.value !== /*name*/ ctx[0]) {
				set_input_value(input, /*name*/ ctx[0]);
			}

			if (dirty & /*users, handleRemove*/ 18) {
				each_value = /*users*/ ctx[1];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div2, destroy_block, create_each_block, t5, get_each_context);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(form);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

const keyup_handler = e => {
	e.preventDefault();
};

const keyup_handler_1 = e => {
	e.preventDefault();
};

function instance($$self, $$props, $$invalidate) {
	let $moims;
	component_subscribe($$self, moims, $$value => $$invalidate(8, $moims = $$value));
	let { slug } = $$props;
	let name = $moims.name;
	let memo = $moims.memo;
	let users = [...$moims.users];
	let removeExists = [];

	const submit = () => {
		window.modal.block('add');

		moims.update(
			{ slug, name, memo, users },
			() => {
				window.modal.close(true);
			},
			() => {
				window.modal.block('remove');
			}
		);
	};

	const handleSubmit = () => {
		if (removeExists.length > 0) {
			return window.modal.confirm(`이 수정은 [${removeExists.join(', ')}]을 삭제하는 것을 포함합니다. 계속하시겠습니까?`, submit);
		}

		const userNames = users.map(user => user.name.trim());

		if (uniq(userNames).length !== users.length) {
			return window.ffb('중복된 이름이 있습니다', 'error');
		}

		submit();
	};

	const handleAdd = async () => {
		$$invalidate(1, users = [...users, { id: +new Date(), name: '' }]);
		await tick();
		jQuery('#moim-form input:last').focusing();
		console.log(`users`, users);
	};

	const handleRemove = userId => {
		if (users.length === 1) {
			return window.modal.alert('최소 1명은 있어야합니다.');
		}

		const targetUser = users.find(user => user.id == userId);

		if (targetUser.remains !== undefined) {
			window.modal.confirm('기존에 있던 유저를 삭제하는 경우 신중하셔야합니다. 계속하시겠습니까?', () => {
				removeExists.push(targetUser.name);
				$$invalidate(1, users = users.filter(user => user.id != userId));
			});
		} else {
			$$invalidate(1, users = users.filter(user => user.id != userId));
		}
	};

	onMount(() => {
		window.modal.update('', 'callback', handleSubmit);
	});

	function input_input_handler() {
		name = this.value;
		$$invalidate(0, name);
	}

	function input_input_handler_1(each_value, user_index) {
		each_value[user_index].name = this.value;
		$$invalidate(1, users);
	}

	$$self.$$set = $$props => {
		if ('slug' in $$props) $$invalidate(5, slug = $$props.slug);
	};

	return [
		name,
		users,
		handleSubmit,
		handleAdd,
		handleRemove,
		slug,
		input_input_handler,
		input_input_handler_1
	];
}

class MoimForm extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { slug: 5 });
	}
}

export default MoimForm;