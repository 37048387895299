/* pages/MoimReset.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_input_value,
	space,
	text
} from "svelte/internal";

import { onMount, tick } from 'svelte';
import moims from '~/stores/moims';

function create_fragment(ctx) {
	let form;
	let p0;
	let t1;
	let p1;
	let t2;
	let span;
	let t4;
	let t5;
	let div;
	let input;
	let mounted;
	let dispose;

	return {
		c() {
			form = element("form");
			p0 = element("p");
			p0.textContent = "만들어진지 1주일 이내거나, 거래갯수가 10개가 미만일 때에는 데이터를\n\t\t리셋할 수 있습니다. 이 기능은 모임을 개설 후 연습삼아 입력된 거래들을\n\t\t삭제하기 위하여 제공됩니다.";
			t1 = space();
			p1 = element("p");
			t2 = text("이 모임의 거래들을 모두 리셋하려면 아래의 입력칸에 `");
			span = element("span");
			span.textContent = `${confirmMessage}`;
			t4 = text("`를 타이핑하십시오");
			t5 = space();
			div = element("div");
			input = element("input");
			attr(p0, "class", "text-danger");
			attr(span, "class", "fw-bold");
			attr(p1, "class", "text-danger");
			attr(input, "placeholder", confirmMessage);
			attr(input, "type", "text");
			attr(input, "class", "form-control");
			attr(div, "class", "mb-3");
			attr(form, "id", "moim-reset");
		},
		m(target, anchor) {
			insert(target, form, anchor);
			append(form, p0);
			append(form, t1);
			append(form, p1);
			append(p1, t2);
			append(p1, span);
			append(p1, t4);
			append(form, t5);
			append(form, div);
			append(div, input);
			/*input_binding*/ ctx[4](input);
			set_input_value(input, /*value*/ ctx[0]);

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[5]),
					listen(input, "keyup", keyup_handler),
					listen(form, "submit", prevent_default(/*handleSubmit*/ ctx[2]))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*value*/ 1 && input.value !== /*value*/ ctx[0]) {
				set_input_value(input, /*value*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(form);
			/*input_binding*/ ctx[4](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

const confirmMessage = '거래데이터 리셋을 원함';

const keyup_handler = e => {
	e.preventDefault();
};

function instance($$self, $$props, $$invalidate) {
	let { slug } = $$props;
	let value = '';
	let inputEl;

	const submit = () => {
		window.modal.block('add');

		moims.resetTransaction(
			slug,
			() => {
				window.blockPage('add');
				location.reload();
			},
			() => {
				window.modal.block('remove');
			}
		);
	};

	const handleSubmit = () => {
		if (value !== confirmMessage) {
			window.ffb('올바른 문구를 입력해주세요', 'error');
			return jQuery(inputEl).focusing();
		}

		window.modal.confirm('마지막으로 묻습니다. 진짜로 리셋을 진행합니까?', submit);
	};

	onMount(() => {
		window.modal.update('', 'callback', handleSubmit);
	});

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			inputEl = $$value;
			$$invalidate(1, inputEl);
		});
	}

	function input_input_handler() {
		value = this.value;
		$$invalidate(0, value);
	}

	$$self.$$set = $$props => {
		if ('slug' in $$props) $$invalidate(3, slug = $$props.slug);
	};

	return [value, inputEl, handleSubmit, slug, input_binding, input_input_handler];
}

class MoimReset extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { slug: 3 });
	}
}

export default MoimReset;