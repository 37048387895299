/* components/PullToRefreshOnIos.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	detach,
	element,
	empty,
	globals,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	svg_element,
	text
} from "svelte/internal";

const { window: window_1 } = globals;

function create_if_block(ctx) {
	let div;
	let svg;
	let path0;
	let path1;
	let t0;
	let t1;

	return {
		c() {
			div = element("div");
			svg = svg_element("svg");
			path0 = svg_element("path");
			path1 = svg_element("path");
			t0 = space();
			t1 = text(/*msg*/ ctx[1]);
			attr(path0, "fill-rule", "evenodd");
			attr(path0, "d", "M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z");
			attr(path1, "d", "M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "width", "16");
			attr(svg, "height", "16");
			attr(svg, "fill", "currentColor");
			attr(svg, "class", "bi bi-arrow-clockwise");
			attr(svg, "viewBox", "0 0 16 16");
			attr(div, "id", "pull-to-refresh-msg");
			set_style(div, "top", /*threshold*/ ctx[0] * -0.5 + "px");
			attr(div, "class", "svelte-1ynduzw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, svg);
			append(svg, path0);
			append(svg, path1);
			append(div, t0);
			append(div, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*msg*/ 2) set_data(t1, /*msg*/ ctx[1]);

			if (dirty & /*threshold*/ 1) {
				set_style(div, "top", /*threshold*/ ctx[0] * -0.5 + "px");
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let scrolling = false;

	let clear_scrolling = () => {
		scrolling = false;
	};

	let scrolling_timeout;
	let if_block_anchor;
	let mounted;
	let dispose;
	add_render_callback(/*onwindowscroll*/ ctx[6]);
	let if_block = /*refreshAfterRelease*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);

			if (!mounted) {
				dispose = listen(window_1, "scroll", () => {
					scrolling = true;
					clearTimeout(scrolling_timeout);
					scrolling_timeout = setTimeout(clear_scrolling, 100);
					/*onwindowscroll*/ ctx[6]();
				});

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*top*/ 4 && !scrolling) {
				scrolling = true;
				clearTimeout(scrolling_timeout);
				scrollTo(window_1.pageXOffset, /*top*/ ctx[2]);
				scrolling_timeout = setTimeout(clear_scrolling, 100);
			}

			if (/*refreshAfterRelease*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const isStandalone = !!window.matchMedia && window.matchMedia('(display-mode: standalone)').matches;
	const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0; // works on IE10/11 and Surface
	const isIos = (/iphone|ipad/i).test(navigator.userAgent);
	let { onlyInPwa = true } = $$props;
	let { threshold = -130 } = $$props;
	let { msg = 'Pull to refresh' } = $$props;

	let { callback = () => {
		location.reload();
	} } = $$props;

	let top = 0;
	let refreshAfterRelease = false;

	function onwindowscroll() {
		$$invalidate(2, top = window_1.pageYOffset)
	}

	$$self.$$set = $$props => {
		if ('onlyInPwa' in $$props) $$invalidate(4, onlyInPwa = $$props.onlyInPwa);
		if ('threshold' in $$props) $$invalidate(0, threshold = $$props.threshold);
		if ('msg' in $$props) $$invalidate(1, msg = $$props.msg);
		if ('callback' in $$props) $$invalidate(5, callback = $$props.callback);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*onlyInPwa, top, threshold, refreshAfterRelease, callback*/ 61) {
			$: {
				if (isTouch && isIos) {
					if (!onlyInPwa || onlyInPwa && isStandalone) {
						if (top < threshold) {
							$$invalidate(3, refreshAfterRelease = true);
						}

						if (top >= 0 && refreshAfterRelease) {
							$$invalidate(3, refreshAfterRelease = false);
							callback();
						}
					}
				}
			}
		}
	};

	return [threshold, msg, top, refreshAfterRelease, onlyInPwa, callback, onwindowscroll];
}

class PullToRefreshOnIos extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			onlyInPwa: 4,
			threshold: 0,
			msg: 1,
			callback: 5
		});
	}
}

export default PullToRefreshOnIos;