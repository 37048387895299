import { writable, get } from 'svelte/store';
import moims from '~/stores/moims';
import ftch from '~/tools/ftch';

const transactions = (() => {
	const _this = writable({
		fetchStatus: 'pending',
		isMore: false,
		rows: []
	});
	const { subscribe, set, update } = _this;

	const setIn = (key, value) => update(_ => {
		_[key] = value;
		return _;
	});

	const fetch = async (slug = '', refresh = false) => {
		setIn('fetchStatus', 'pending');
		const _ = get(_this);
		let maxId = 99999999999999;
		if (!refresh && _.rows.length > 0) {
			maxId = _.rows[_.rows.length - 1].id;
		}
		const limit = 10;
		const {status, result} = await ftch('get', '/api/transactions', {
			slug,
			maxId,
			limit
		});
		if (status === 'fail') {
			return window.ffb('실패', 'error');
		}

		_.fetchStatus = '';
		if (refresh) {
			_.rows = [...result];
		} else {
			_.rows = [..._.rows, ...result];
		}
		if (result.length === 24) {
			_.isMore = true;
		}
		set(_);
	}

	return {
		subscribe,
		set,
		setIn,

		fetch,

		create: async (slug, store, callback, cancelCallback) => {
			const { status, message } = await ftch('post', '/api/transactions', {
				slug,
				userId: store.userId,
				amount: store.amount,
				memo: store.memo,
				users: store.users.filter(user => {
					if (!store.showAll) { // 모두보기가 아니라면
						if (user.isJoin) { // 참가자만 솎음(결제자도 이 안에 있음), 결제금액이 있어도 참가자가 아니면 제외
							return true;
						}
					} else { // 모두보기라면
						if (user.isJoin || user.amount != '') { // 참석했거나 결제금액이 설정된 사람만 참여
							return true;
						}
					}
					return false;
				}).map(({id, isJoin, isPay, amount}) => {
					return {
						id,
						isJoin,
						amount: isPay ? Number(amount) : 0 // 결제 선택된 사람만 금액 입력
					};
				})
			});
			if (status === 'done') {
				moims.fetch(slug);
				transactions.fetch(slug, true);
				window.ffb(message);
				typeof callback === 'function' && callback();
			} else {
				window.ffb(message, 'error');
				typeof cancelCallback === 'function' && cancelCallback();
			}
		},

		cancel: async(slug, transactionId) => {
			setIn('fetchStatus', 'pending');
			const { status, message } = await ftch('post', '/api/transactions-cancel', {
				slug,
				transactionId
			});
			window.ffb(message, status);
			moims.fetch(slug, true);
			transactions.fetch(slug, true);
		},
	};
})();

export default transactions;