/* pages/TransactionForm.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_block,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_style,
	space,
	text,
	to_number,
	toggle_class,
	update_keyed_each
} from "svelte/internal";

import { onMount, tick } from "svelte";
import moims from '~/stores/moims';
import comma from '~/tools/comma';
import transactions from '~/stores/transactions';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	child_ctx[16] = list;
	child_ctx[17] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

// (85:2) {#each users as user (user.id)}
function create_each_block_2(key_1, ctx) {
	let button;
	let t_value = /*user*/ ctx[15].name + "";
	let t;
	let mounted;
	let dispose;

	return {
		key: key_1,
		first: null,
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "type", "button");
			attr(button, "class", "border btn btn-sm me-1 mb-1");
			toggle_class(button, "btn-secondary", /*user*/ ctx[15].isJoin);
			toggle_class(button, "btn-secondary-outline", !/*user*/ ctx[15].isJoin);
			this.first = button;
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*handleTogggleJoin*/ ctx[5].bind(null, /*user*/ ctx[15].id))) /*handleTogggleJoin*/ ctx[5].bind(null, /*user*/ ctx[15].id).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*users*/ 1 && t_value !== (t_value = /*user*/ ctx[15].name + "")) set_data(t, t_value);

			if (dirty & /*users*/ 1) {
				toggle_class(button, "btn-secondary", /*user*/ ctx[15].isJoin);
			}

			if (dirty & /*users*/ 1) {
				toggle_class(button, "btn-secondary-outline", !/*user*/ ctx[15].isJoin);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (96:2) {#each payCandidateUsers as user (user.id)}
function create_each_block_1(key_1, ctx) {
	let button;
	let t_value = /*user*/ ctx[15].name + "";
	let t;
	let mounted;
	let dispose;

	return {
		key: key_1,
		first: null,
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "type", "button");
			attr(button, "class", "border btn btn-sm me-1 mb-1");
			toggle_class(button, "btn-info", /*user*/ ctx[15].isPay);
			toggle_class(button, "btn-secondary-outline", !/*user*/ ctx[15].isPay);
			this.first = button;
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(/*handleToggglePay*/ ctx[6].bind(null, /*user*/ ctx[15].id))) /*handleToggglePay*/ ctx[6].bind(null, /*user*/ ctx[15].id).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*payCandidateUsers*/ 4 && t_value !== (t_value = /*user*/ ctx[15].name + "")) set_data(t, t_value);

			if (dirty & /*payCandidateUsers*/ 4) {
				toggle_class(button, "btn-info", /*user*/ ctx[15].isPay);
			}

			if (dirty & /*payCandidateUsers*/ 4) {
				toggle_class(button, "btn-secondary-outline", !/*user*/ ctx[15].isPay);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (99:2) {#if payCandidateUsers.length === 0}
function create_if_block_1(ctx) {
	let span;

	return {
		c() {
			span = element("span");
			span.textContent = "참석자를 선택하세요";
			set_style(span, "opacity", "0.5");
			set_style(span, "font-size", "0.8em");
		},
		m(target, anchor) {
			insert(target, span, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (104:2) {#if (store.showAll && user.isPay) || (user.isJoin && user.isPay)}
function create_if_block(ctx) {
	let div;
	let label;
	let i;
	let t0;
	let t1_value = /*user*/ ctx[15].name + "";
	let t1;
	let t2;
	let t3;
	let input;
	let mounted;
	let dispose;

	function input_input_handler() {
		/*input_input_handler*/ ctx[11].call(input, /*each_value*/ ctx[16], /*user_index*/ ctx[17]);
	}

	return {
		c() {
			div = element("div");
			label = element("label");
			i = element("i");
			t0 = space();
			t1 = text(t1_value);
			t2 = text(" 결제금액");
			t3 = space();
			input = element("input");
			attr(i, "class", "bi bi-wallet2");
			attr(label, "class", "d-block control-label mb-1");
			attr(input, "type", "number");
			attr(input, "inputmode", "decimal");
			attr(input, "class", "form-control");
			attr(div, "class", "mb-3");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			append(label, i);
			append(label, t0);
			append(label, t1);
			append(label, t2);
			append(div, t3);
			append(div, input);
			set_input_value(input, /*user*/ ctx[15].amount);

			if (!mounted) {
				dispose = listen(input, "input", input_input_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*users*/ 1 && t1_value !== (t1_value = /*user*/ ctx[15].name + "")) set_data(t1, t1_value);

			if (dirty & /*users*/ 1 && to_number(input.value) !== /*user*/ ctx[15].amount) {
				set_input_value(input, /*user*/ ctx[15].amount);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (103:1) {#each users as user (user.id)}
function create_each_block(key_1, ctx) {
	let first;
	let if_block_anchor;
	let if_block = (/*store*/ ctx[1].showAll && /*user*/ ctx[15].isPay || /*user*/ ctx[15].isJoin && /*user*/ ctx[15].isPay) && create_if_block(ctx);

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (/*store*/ ctx[1].showAll && /*user*/ ctx[15].isPay || /*user*/ ctx[15].isJoin && /*user*/ ctx[15].isPay) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(first);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let form;
	let div0;
	let label0;
	let i0;
	let t0;
	let t1;
	let t2;
	let t3;
	let each_blocks_2 = [];
	let each0_lookup = new Map();
	let t4;
	let div1;
	let label1;
	let i1;
	let t5;
	let t6;
	let t7;
	let a;
	let t9;
	let each_blocks_1 = [];
	let each1_lookup = new Map();
	let t10;
	let t11;
	let each_blocks = [];
	let each2_lookup = new Map();
	let t12;
	let div2;
	let label2;
	let t14;
	let input;
	let t15;
	let button;
	let mounted;
	let dispose;
	let each_value_2 = /*users*/ ctx[0];
	const get_key = ctx => /*user*/ ctx[15].id;

	for (let i = 0; i < each_value_2.length; i += 1) {
		let child_ctx = get_each_context_2(ctx, each_value_2, i);
		let key = get_key(child_ctx);
		each0_lookup.set(key, each_blocks_2[i] = create_each_block_2(key, child_ctx));
	}

	let each_value_1 = /*payCandidateUsers*/ ctx[2];
	const get_key_1 = ctx => /*user*/ ctx[15].id;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1(ctx, each_value_1, i);
		let key = get_key_1(child_ctx);
		each1_lookup.set(key, each_blocks_1[i] = create_each_block_1(key, child_ctx));
	}

	let if_block = /*payCandidateUsers*/ ctx[2].length === 0 && create_if_block_1(ctx);
	let each_value = /*users*/ ctx[0];
	const get_key_2 = ctx => /*user*/ ctx[15].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key_2(child_ctx);
		each2_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			form = element("form");
			div0 = element("div");
			label0 = element("label");
			i0 = element("i");
			t0 = text("\n\t\t\t참석자(");
			t1 = text(/*joinedCount*/ ctx[4]);
			t2 = text("명)");
			t3 = space();

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			t4 = space();
			div1 = element("div");
			label1 = element("label");
			i1 = element("i");
			t5 = text("\n\t\t\t결제자(");
			t6 = text(/*payCount*/ ctx[3]);
			t7 = text("명)\n\t\t\t\n\t\t\t");
			a = element("a");
			a.textContent = "모두보기";
			t9 = space();

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t10 = space();
			if (if_block) if_block.c();
			t11 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t12 = space();
			div2 = element("div");
			label2 = element("label");

			label2.innerHTML = `<i class="bi bi-shop"></i>
			사용처 및 메모`;

			t14 = space();
			input = element("input");
			t15 = space();
			button = element("button");
			attr(i0, "class", "bi bi-people");
			attr(label0, "class", "d-block control-label mb-2");
			attr(div0, "class", "mb-3");
			attr(i1, "class", "bi bi-people-fill");
			attr(a, "href", "#3");
			attr(a, "class", "btn-link");
			set_style(a, "font-size", "0.8em");
			set_style(a, "color", "gray");
			attr(a, "data-title", "결제자가 참석하지 않은 경우");
			attr(label1, "class", "d-block control-label mb-2");
			attr(div1, "class", "mb-3");
			attr(label2, "class", "d-block control-label mb-1");
			attr(input, "type", "text");
			attr(input, "class", "form-control");
			attr(div2, "class", "mb-3");
			attr(button, "type", "submit");
			attr(button, "class", "visually-hidden");
			attr(form, "id", "transaction-form");
		},
		m(target, anchor) {
			insert(target, form, anchor);
			append(form, div0);
			append(div0, label0);
			append(label0, i0);
			append(label0, t0);
			append(label0, t1);
			append(label0, t2);
			append(div0, t3);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				if (each_blocks_2[i]) {
					each_blocks_2[i].m(div0, null);
				}
			}

			append(form, t4);
			append(form, div1);
			append(div1, label1);
			append(label1, i1);
			append(label1, t5);
			append(label1, t6);
			append(label1, t7);
			append(label1, a);
			append(div1, t9);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(div1, null);
				}
			}

			append(div1, t10);
			if (if_block) if_block.m(div1, null);
			append(form, t11);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(form, null);
				}
			}

			append(form, t12);
			append(form, div2);
			append(div2, label2);
			append(div2, t14);
			append(div2, input);
			set_input_value(input, /*store*/ ctx[1].memo);
			append(form, t15);
			append(form, button);

			if (!mounted) {
				dispose = [
					listen(a, "click", /*toggleShowAll*/ ctx[7]),
					listen(input, "input", /*input_input_handler_1*/ ctx[12]),
					listen(form, "submit", prevent_default(/*handleSubmit*/ ctx[8]))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*joinedCount*/ 16) set_data(t1, /*joinedCount*/ ctx[4]);

			if (dirty & /*users, handleTogggleJoin*/ 33) {
				each_value_2 = /*users*/ ctx[0];
				each_blocks_2 = update_keyed_each(each_blocks_2, dirty, get_key, 1, ctx, each_value_2, each0_lookup, div0, destroy_block, create_each_block_2, null, get_each_context_2);
			}

			if (dirty & /*payCount*/ 8) set_data(t6, /*payCount*/ ctx[3]);

			if (dirty & /*payCandidateUsers, handleToggglePay*/ 68) {
				each_value_1 = /*payCandidateUsers*/ ctx[2];
				each_blocks_1 = update_keyed_each(each_blocks_1, dirty, get_key_1, 1, ctx, each_value_1, each1_lookup, div1, destroy_block, create_each_block_1, t10, get_each_context_1);
			}

			if (/*payCandidateUsers*/ ctx[2].length === 0) {
				if (if_block) {
					
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*users, store*/ 3) {
				each_value = /*users*/ ctx[0];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key_2, 1, ctx, each_value, each2_lookup, form, destroy_block, create_each_block, t12, get_each_context);
			}

			if (dirty & /*store*/ 2 && input.value !== /*store*/ ctx[1].memo) {
				set_input_value(input, /*store*/ ctx[1].memo);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(form);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].d();
			}

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].d();
			}

			if (if_block) if_block.d();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let joinedCount;
	let payCount;
	let payCandidateUsers;
	let $moims;
	component_subscribe($$self, moims, $$value => $$invalidate(13, $moims = $$value));
	let { slug } = $$props;

	let users = [
		...$moims.users.map(user => {
			user.isJoin = false;
			user.isPay = false;
			user.amount = '';
			return user;
		})
	];

	let store = { showAll: false, memo: '' };
	let total = 0;

	const handleTogggleJoin = (userId, e) => {
		const userIndex = users.findIndex(user => user.id == userId);
		$$invalidate(0, users[userIndex].isJoin = !users[userIndex].isJoin, users);
	};

	const handleToggglePay = userId => {
		const userIndex = users.findIndex(user => user.id == userId);
		$$invalidate(0, users[userIndex].isPay = !users[userIndex].isPay, users);
	};

	const submit = () => {
		window.modal.block('add');

		transactions.create(
			slug,
			{ ...store, users },
			() => {
				window.modal.close(true);
			},
			() => {
				window.modal.block('remove');
			}
		);
	};

	const toggleShowAll = () => {
		$$invalidate(1, store.showAll = !store.showAll, store);
	};

	const handleSubmit = () => {
		if (users.filter(user => user.isJoin).length === 0) {
			return window.ffb('최소 1명 이상의 참석자를 선택하세요', 'error');
		}

		if (users.find(user => store.showAll && user.isPay || user.isJoin && user.isPay) === undefined) {
			return window.ffb('유효한 결제자를 지정하세요', 'error');
		}

		if (total == 0) {
			return window.ffb('금액을 입력하세요', 'error');
		}

		window.modal.confirm(`총 사용금액이 ${comma(total)}원 맞습니까?`, submit);
	};

	onMount(() => {
		window.modal.update('', 'callback', handleSubmit);
	});

	function input_input_handler(each_value, user_index) {
		each_value[user_index].amount = to_number(this.value);
		$$invalidate(0, users);
	}

	function input_input_handler_1() {
		store.memo = this.value;
		$$invalidate(1, store);
	}

	$$self.$$set = $$props => {
		if ('slug' in $$props) $$invalidate(9, slug = $$props.slug);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*users*/ 1) {
			$: $$invalidate(4, joinedCount = users.filter(user => user.isJoin).length);
		}

		if ($$self.$$.dirty & /*users, store*/ 3) {
			$: $$invalidate(3, payCount = users.filter(user => store.showAll && user.isPay || user.isJoin && user.isPay).length);
		}

		if ($$self.$$.dirty & /*users, store, total*/ 1027) {
			$: {
				$$invalidate(10, total = 0);

				users.filter(user => store.showAll && user.isPay || user.isJoin && user.isPay).forEach(user => {
					$$invalidate(10, total += user.amount);
				});

				jQuery('.modal-footer-spare').html(`총 결제 : ${comma(total)}`);
			}
		}

		if ($$self.$$.dirty & /*users, store*/ 3) {
			$: $$invalidate(2, payCandidateUsers = users.filter(user => store.showAll || user.isJoin));
		}
	};

	return [
		users,
		store,
		payCandidateUsers,
		payCount,
		joinedCount,
		handleTogggleJoin,
		handleToggglePay,
		toggleShowAll,
		handleSubmit,
		slug,
		total,
		input_input_handler,
		input_input_handler_1
	];
}

class TransactionForm extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { slug: 9 });
	}
}

export default TransactionForm;