/* components/BlockPage.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

function create_if_block(ctx) {
	let div2;

	return {
		c() {
			div2 = element("div");
			div2.innerHTML = `<div class="p-5 rounded absolute-center svelte-2tara6"><div class="spinner-border svelte-2tara6" role="status"></div></div>`;
			attr(div2, "id", "block-page");
			attr(div2, "class", "svelte-2tara6");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
		},
		d(detaching) {
			if (detaching) detach(div2);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*blocking*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*blocking*/ ctx[0]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let blocking = false;

	window.blockPage = function (method, fromAuto) {
		if (typeof fromAuto === 'undefined') {
			fromAuto = false;
		}

		if (!fromAuto && method === 'add') {
			blockPage.preventAutoClose = true;
		}

		switch (method) {
			case 'add':
				{
					$$invalidate(0, blocking = true);
					jQuery('*:focus').blur();
					break;
				}
			case 'remove':
				{
					if (typeof blockPage.preventAutoClose !== 'undefined') {
						if (fromAuto) {
							return false;
						} else {
							// 회수
							delete blockPage.preventAutoClose;
						}
					}

					$$invalidate(0, blocking = false);
					break;
				}
			default:
				{
					$$invalidate(0, blocking = !blocking);
				}
		}
	};

	return [blocking];
}

class BlockPage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default BlockPage;