/* components/ModalSelector.svelte generated by Svelte v3.58.0 */
import {
	HtmlTag,
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	destroy_block,
	detach,
	element,
	init,
	init_binding_group,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	space,
	toggle_class,
	update_keyed_each
} from "svelte/internal";

import { onMount } from 'svelte';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i][0];
	child_ctx[11] = list[i][1];
	return child_ctx;
}

// (52:0) {#if desc !== ''}
function create_if_block(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			attr(p, "class", "desc");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			p.innerHTML = /*desc*/ ctx[1];
		},
		p(ctx, dirty) {
			if (dirty & /*desc*/ 2) p.innerHTML = /*desc*/ ctx[1];;
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (58:1) {#each Object.entries(options) as [key, label] (key)}
function create_each_block(key_1, ctx) {
	let div;
	let label;
	let input;
	let input_value_value;
	let value_has_changed = false;
	let t0;
	let html_tag;
	let raw_value = /*label*/ ctx[11] + "";
	let t1;
	let binding_group;
	let mounted;
	let dispose;
	binding_group = init_binding_group(/*$$binding_groups*/ ctx[8][0]);

	return {
		key: key_1,
		first: null,
		c() {
			div = element("div");
			label = element("label");
			input = element("input");
			t0 = space();
			html_tag = new HtmlTag(false);
			t1 = space();
			attr(input, "class", "form-check-input");
			attr(input, "type", "radio");
			attr(input, "name", "option");
			input.__value = input_value_value = /*key*/ ctx[10];
			input.value = input.__value;
			html_tag.a = null;
			attr(label, "class", "form-check-label d-block svelte-18m41f0");
			attr(div, "class", "form-check list-group-item svelte-18m41f0");
			toggle_class(div, "current", /*key*/ ctx[10] == /*thisValue*/ ctx[2]);
			binding_group.p(input);
			this.first = div;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			append(label, input);
			input.checked = input.__value === /*thisValue*/ ctx[2];
			append(label, t0);
			html_tag.m(raw_value, label);
			append(div, t1);

			if (!mounted) {
				dispose = [
					listen(input, "change", /*input_change_handler*/ ctx[7]),
					listen(input, "change", change_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*options*/ 1 && input_value_value !== (input_value_value = /*key*/ ctx[10])) {
				input.__value = input_value_value;
				input.value = input.__value;
				value_has_changed = true;
			}

			if (value_has_changed || dirty & /*thisValue, options*/ 5) {
				input.checked = input.__value === /*thisValue*/ ctx[2];
			}

			if (dirty & /*options*/ 1 && raw_value !== (raw_value = /*label*/ ctx[11] + "")) html_tag.p(raw_value);

			if (dirty & /*Object, options, thisValue*/ 5) {
				toggle_class(div, "current", /*key*/ ctx[10] == /*thisValue*/ ctx[2]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			binding_group.r();
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let t;
	let div;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let if_block = /*desc*/ ctx[1] !== '' && create_if_block(ctx);
	let each_value = Object.entries(/*options*/ ctx[0]);
	const get_key = ctx => /*key*/ ctx[10];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "list-group svelte-18m41f0");
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			/*div_binding*/ ctx[9](div);
		},
		p(ctx, [dirty]) {
			if (/*desc*/ ctx[1] !== '') {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*Object, options, thisValue*/ 5) {
				each_value = Object.entries(/*options*/ ctx[0]);
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, destroy_block, create_each_block, null, get_each_context);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
			if (detaching) detach(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			/*div_binding*/ ctx[9](null);
		}
	};
}

const change_handler = e => {
	
}; // console.log(`e`, e);

function instance($$self, $$props, $$invalidate) {
	let { options = {} } = $$props;
	let { desc = '' } = $$props;
	let { value = '' } = $$props;
	let { callback } = $$props;
	let { cancelCallback } = $$props;
	let thisValue = value;
	let wrapperEl;

	onMount(() => {
		if (typeof callback === 'function') {
			window.modal.update('', 'callback', () => {
				callback(thisValue);
				window.modal.close(true);
			});
		}

		if (typeof cancelCallback === 'function') {
			window.modal.update('', 'cancelCallback', () => {
				cancelCallback(thisValue);
				window.modal.close(true);
			});
		}

		setTimeout(
			() => {
				jQuery(wrapperEl).find('input:checked').focus();
			},
			100
		);
	});

	const $$binding_groups = [[]];

	function input_change_handler() {
		thisValue = this.__value;
		$$invalidate(2, thisValue);
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			wrapperEl = $$value;
			$$invalidate(3, wrapperEl);
		});
	}

	$$self.$$set = $$props => {
		if ('options' in $$props) $$invalidate(0, options = $$props.options);
		if ('desc' in $$props) $$invalidate(1, desc = $$props.desc);
		if ('value' in $$props) $$invalidate(4, value = $$props.value);
		if ('callback' in $$props) $$invalidate(5, callback = $$props.callback);
		if ('cancelCallback' in $$props) $$invalidate(6, cancelCallback = $$props.cancelCallback);
	};

	return [
		options,
		desc,
		thisValue,
		wrapperEl,
		value,
		callback,
		cancelCallback,
		input_change_handler,
		$$binding_groups,
		div_binding
	];
}

class ModalSelector extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			options: 0,
			desc: 1,
			value: 4,
			callback: 5,
			cancelCallback: 6
		});
	}
}

export default ModalSelector;