export default function jsonStringify(obj){
	// Note: cache should not be re-used by repeated calls to JSON.stringify.
	const cache = [];
	const stringified = JSON.stringify(obj, function (key, value) {
		if (key === 'store' || key === 'parent') {
			return undefined;
		}
		if (typeof value === 'object' && value !== null) {
			if (cache.indexOf(value) !== -1) {
				// Duplicate reference found
				try {
					// If this value does not reference a parent it can be deduped
					return JSON.parse(JSON.stringify(value));
				} catch (error) {
					// discard key if value cannot be deduped
					return;
				}
			}
			// Store value in our collection
			cache.push(value);
		}
		return value;
	});
	
	return stringified;
};